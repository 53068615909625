import React from "react";
import style from './CompositionCard.module.css';
import roleIcon from '../../assets/role_icon.svg';
import classIcon from '../../assets/class_icon.svg';
import factionIcon from '../../assets/faction_icon.svg';
import elementIcon from '../../assets/element_icon.svg';

import { Roles, Classes, Elements, Factions } from '../../content/attributes';
import NAIcon from '../../assets/icons/naIcon.svg'

const CompositionCard = props => {
	const guardianCardWrapperStyle = {
		backgroundImage: 'url(' + props.guardianImage + ')'
	}

	const selectedRole = Roles.find(role => role.name === props.guardianRole)
	const roleIcon = selectedRole ? selectedRole.icon : NAIcon

	const selectedClass = Classes.find(heroClass => heroClass.name === props.guardianClass)
	const classIcon = selectedClass ? selectedClass.icon : NAIcon

	const selectedElement = Elements.find(element => element.name === props.guardianElement)
	const elementIcon = selectedElement ? selectedElement.icon : NAIcon

	const selectedFaction = Factions.find(heroClass => heroClass.name === props.guardianFaction)
	const factionIcon = selectedFaction ? selectedFaction.icon : NAIcon

	return (
		<div className={style.GuardianCardWrapper} style={guardianCardWrapperStyle}>
			<div
				src={props.guardianImage}
				alt=""
				className={style.GuardianImage}
			/>
			<div className={style.GuardianCardInfo}>
				<div className={style.Guardian}>
					<div className={style.GuardianCardInfo}>
						<div className={style.GuardianNameWrapper}>
							<span className={style.GuardianName}>{props.guardianName}</span>
						</div>

						<div className={style.GuardianAddWrapper}>

							<button
								className={style.AddButton}
								onClick={props.click}
							>
								Add to squad
							</button>
						</div>
					</div>

					<div className={style.GuardianAttributeWrapper}>
						<div className={style.GuardianAttribute}>
							<img className={style.CompositionIcon} src={roleIcon} alt="" />
							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>Role</span>
								<span className={style.GuardianAttributeText}>{props.guardianRole}</span>
							</div>
						</div>

						<div className={style.GuardianAttribute}>
							<img className={style.CompositionIcon} src={classIcon} alt="" />
							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>Class</span>
								<span className={style.GuardianAttributeText}>{props.guardianClass}</span>
							</div>
						</div>
					</div>

					<div className={style.GuardianAttributeWrapper}>
						<div className={style.GuardianAttribute}>
							<img className={style.CompositionIcon} src={factionIcon} alt="" />
							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>Faction</span>
								<span className={style.GuardianAttributeText}>{props.guardianFaction}</span>
							</div>
						</div>

						<div className={style.GuardianAttribute}>
							<img className={style.CompositionIcon} src={elementIcon} alt="" />
							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>
									Element
								</span>
								<span className={style.GuardianAttributeText}>{props.guardianElement}</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}

export default CompositionCard;