import React, { useState } from 'react';
import axios from 'axios';
import style from './FileUpload.module.css';

const API_URL = process.env.REACT_APP_BACKEND_API;

const FileUpload = props => {
	const [selectedImage, setSelectedImage] = useState(null);
	const [uploadStatus, setUploadStatus] = useState('');

	const handleImageUpload = (event) => {
		const file = event.target.files[0];
		setSelectedImage(URL.createObjectURL(file));

		// Create a FormData object to send the file
		const formData = new FormData();
		formData.append('image', file);

		// Make a POST request to the image upload endpoint using Axios
		setUploadStatus('uploading');
		axios.post(`${API_URL}/uploads/image-upload`, formData)
		.then(response => {
				// Handle successful upload
				props.onUploadComplete(response.data.secure_url)
				setUploadStatus('uploaded');
				console.log(response.data);
			})
			.catch(error => {
				// Handle upload error
				setUploadStatus('error');
				console.error(error);
			});
	};

	return (
		<div className={style.imageUploadWrapper}>
			<input type="file" onChange={handleImageUpload} className={style.ImageUpload} />
			{uploadStatus === 'uploading' && <p>Uploading...</p>}
			{uploadStatus === 'uploaded' && <p>Uploaded</p>}
			{selectedImage && <img src={selectedImage} alt="Uploaded" className={style.uploadedImage} />}
		</div>
	);
}

export default FileUpload;
