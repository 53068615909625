import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";

import style from './TeamCompositionOverlay.module.css';
import MainInput from '../../components/MainInput/MainInput';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import SavedSquad from "../../components/SavedSquad/SavedSquad";
import SquadOverview from "../../components/SquadOverview/SquadOverview";
import SquadItem from "../../components/SquadItem/SquadItem";
import Spinner from '../../components/Spinner/Spinner';

import roleIcon from '../../assets/role_icon.svg';
import classIcon from '../../assets/class_icon.svg';
import factionIcon from '../../assets/faction_icon.svg';
import elementIcon from '../../assets/element_icon.svg';

const TeamCompositionOverlay = props => {
	const { user } = useUser();
	const API_URL = process.env.REACT_APP_BACKEND_API;

	const [SquadArray, setSquadArray] = useState({
		squad: [
			{
				squadName: '',
				guardians: [
					{
						name: '',
						role: '',
						class: '',
						faction: '',
						element: '',
						imageURL: ''
					}
				]
			}
		]
	});

	// const [selectedSquad, setSelectedSquad] = useState(SquadArray.squad[0].guardians)
	const [selectedSquad, setSelectedSquad] = useState(props.guardians)
	const [selectedSquadIndex, setSelectedSquadIndex] = useState(0)
	const [savedSquad, setSavedSquad] = useState(null)

	const getSquadName = () => {
		console.log('test');
	}

	const getSquad = () => {
		axios.get(`${API_URL}/squad/${user.id}`)
			.then(res => setSavedSquad(res.data))
			.catch(err => console.log(err))
	}

	const saveSquadHandler = () => {
		// Send the saved squad array to the backend when the save squad btn is clicked
		axios.post(`${API_URL}/squad/${user.id}`, SquadArray)
			.then(
				res => console.log(res)
			).catch(err => err)
	}

	useEffect(() => {
		if (user) {
			getSquad()
		}
	}, [savedSquad])

	useEffect(() => {
		props.updateSelectedSquad(selectedSquad);
	}, [selectedSquad]);

	useEffect(() => {
		const { guardians } = props;
		const squadName = getSquadName();
		let newSqaud = { ...SquadArray };
		newSqaud.squad[0].squadName = squadName;

		const squadGuardians = guardians.map(guardian => {
			return {
				name: guardian.name,
				role: guardian.role,
				class: guardian.class,
				faction: guardian.faction,
				element: guardian.element,
				imageURL: guardian.imageURL
			}
		})

		newSqaud.squad[0].guardians = squadGuardians;
		setSquadArray(newSqaud);
	}, [props.guardians])

	// console.log(props.savedSquad)

	const squadNameHandler = (e) => {
		const squadName = e.target.value;
		const newSquadArray = { ...SquadArray };
		newSquadArray.squad[0].squadName = squadName
		setSquadArray(newSquadArray)
	}

	const removeGuardian = (guardian) => {
		setSelectedSquad(prevSquad => {
			const updatedSquad = prevSquad.filter(g => g !== guardian);
			return updatedSquad;
		});
	};


	return (
		user ?
			<div className={style.TeamCompositionOverlayContainer}>
				<div className={style.Overlay}>
					<div className={style.TeamCompositionOverlay}
						onClick={e => e.stopPropagation()}>
						<div className={style.SquadInputWrapper}>
							<div className={style.SquadInputContainer}>
								<MainInput
									placeholder='Squad Name'
									change={(e) => squadNameHandler(e)}
								/>
								<PrimaryCTA
									buttonText='Save Squad'
									buttonClick={saveSquadHandler}
								/>
							</div>

							<button 
							className={style.CloseOverlayButton}
							onClick={() => props.overlayState()}
							>
								X
							</button>
						</div>

						<div className={style.SavedSquadContainer}>
							{savedSquad ? (
								savedSquad.map((Squad, squadIndex) => {
									const firstLetter = Squad.squadName ? Squad.squadName.charAt(0).toUpperCase() : '';

									return (
										<div
											className={style.SavedSquad}
											onClick={() => setSelectedSquad(Squad.guardians)}
										>
											<span className={style.SavedSquadLetter}>{firstLetter}</span>
											<span className={style.SquadName}>{Squad.squadName}</span>
										</div>
									);
								})
							) : (
								<div className='SpinnerContainer'>
									<Spinner />
								</div>
							)}
						</div>

						<div className={style.SquadOverviewContainer}>
							<h2 className={style.SquadOverviewHeading}>
								Squad Stat Overview
							</h2>

							<SquadOverview
								Icon={roleIcon}
								Role="Role"
								Attribute="role"
								selectedSquad={selectedSquad}
							/>

							<SquadOverview
								Icon={factionIcon}
								Role="Faction"
								Attribute="faction"
								selectedSquad={selectedSquad}
							/>

							<SquadOverview
								Icon={classIcon}
								Role="Class"
								Attribute="class"
								selectedSquad={selectedSquad}
							/>

							<SquadOverview
								Icon={elementIcon}
								Role="Element"
								Attribute="element"
								selectedSquad={selectedSquad}
							/>
						</div>

						<h2 className={style.SquadHeading}>
							Squad
						</h2>


						<div className={style.SquadItemContainer}>
							{selectedSquad.map((guardian, index) => (
								<SquadItem
									key={guardian._id}
									guardianName={guardian.name}
									guardianRole={guardian.role}
									guardianElement={guardian.element}
									guardianClass={guardian.class}
									guardianFaction={guardian.faction}
									guardianImage={guardian.imageURL}
									Click={() => removeGuardian(guardian)}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			: <span>Log in to save and edit your squad</span>
	)
}

export default TeamCompositionOverlay;