import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import '../AdminPage/Admin.css'
// import style from './AdminEditHero.module.css';
import style from '../AdminEditHero/AdminEditHero.module.css'

import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { isAdmin } from '../../utils/userUtils';
import handleRequest from '../../utils/inputCheck';

import AdminSideBar from './../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import MainInput from '../../components/MainInput/MainInput';
import WikiText from '../../components/WikiText/WikiText';
import MainDropdown from '../../components/MainDropdown/MainDropdown';
import AdminPetPreviewPage from '../AdminPetPreviewPage/AdminPetPreviewPage';
import FileUpload from '../../components/FileUpload/FileUpload';

import { Roles } from '../../content/attributes';
import { Classes } from '../../content/attributes';
import { Elements } from '../../content/attributes';
import { Factions } from '../../content/attributes';
import { Rarity } from '../../content/attributes';
import { Editions } from '../../content/attributes';
import { PassiveAbilities } from '../../content/attributes';
import { ActiveAbilities } from '../../content/attributes';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminEditHero = () => {
	const { petID } = useParams()
	const navigate = useNavigate()
	const { user } = useUser()

	const [showPreview, setShowPreview] = useState(false);
	// const [hero, setHero] = useState({})
	const [petData, setPetData] = useState({
		name: '',
		imageURL: '',
		description: '',
		price: 0,
		role: '',
		class: '',
		faction: '',
		element: '',
		rarity: '',
		edition: '',
		activeAbility: '',
		passiveAbility: '',
		overview: '',
		wikiText: ''
	});

	const getGuardianInfo = () => {
		axios.get(`${API_URL}/pets/${petID}`)
			.then(res => setPetData(res.data))
			// .then(res => setPetData(hero))
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getGuardianInfo()
	}, [])

	const handleSubmit = async (e) => {
		toast('updating pet');
		handleRequest(
			petData,
			user,
			user.id,
			`${API_URL}/pets/${petID}`,
			'patch',
			`Updated ${petData.name}`,
			navigate,
			'/admin-pets'
		);
	};


	// Input field change handlers
	const handleNameChange = event => {
		setPetData({ ...petData, name: event.target.value });
	}

	const handleImageLinkChange = event => {
		setPetData({ ...petData, imageURL: event.target.value });
	}

	const handleDescriptionChange = event => {
		setPetData({ ...petData, description: event.target.value });
	}

	// const handlePriceChange = event => {
	// 	setPetData({ ...petData, price: event.target.value });
	// }

	const handleRoleChange = selectedValue => {
		setPetData({ ...petData, role: selectedValue });
	}

	const handleClassChange = selectedValue => {
		setPetData({ ...petData, class: selectedValue });
	}

	const handleFactionChange = selectedValue => {
		setPetData({ ...petData, faction: selectedValue });
	}

	const handleElementChange = selectedValue => {
		setPetData({ ...petData, element: selectedValue });
	}

	const handleRarityChange = selectedValue => {
		setPetData({ ...petData, rarity: selectedValue });
	}

	const handleEditionChange = selectedValue => {
		setPetData({ ...petData, edition: selectedValue });
	}

	// const handleActiveAbilityChange = selectedValue => {
	// 	setPetData({ ...petData, activeAbility: selectedValue });
	// }

	// const handlePassiveAbilityChange = selectedValue => {
	// 	setPetData({ ...petData, passiveAbility: selectedValue });
	// }

	const handleOverviewChange = event => {
		setPetData({ ...petData, overview: event.target.value });
	}

	const handleWikiTextChange = event => {
		setPetData({ ...petData, wikiText: event.target.value });
	}

	const handleSetUploadLink = (imageLink) => {
		setPetData({ ...petData, imageURL: imageLink });
	};

	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<div className='AdminTopBar'>
					<h2>Editing {petData.name} </h2>
					<div className='AdminButtonWrapper'>
						<span onClick={
							() => setShowPreview(!showPreview)
						}>{showPreview ? 'Hide Preview' : 'Show Preview'}</span>

						<PrimaryCTA buttonText='Update Pet'
							buttonClick={handleSubmit}
						/>
					</div>
				</div>

				{
					showPreview ?
						<AdminPetPreviewPage
							petData={petData}
						/>
						:
						<div className={style.HeroPageContent}>
							<div className={style.HeroPageInputContainer}>
								<div className={style.HeroInputsWrapper}>
									<MainInput
										TitleText='Pet Name'
										placeholder='Pet name'
										change={handleNameChange}
										value={petData.name || ''}
									/>

									<MainInput
										TitleText='Pet Image Link'
										palceholder='Pet Image Link'
										change={handleImageLinkChange}
										value={petData.imageURL || ''}
									/>

									<FileUpload
										onUploadComplete={handleSetUploadLink}
									/>

									<MainInput
										TitleText='Pet Description'
										palceholder='Pet Description'
										change={handleDescriptionChange}
										value={petData.description || ''}
									/>

									<MainInput
										TitleText='Pet Overview'
										palceholder='Pet Overview'
										change={handleOverviewChange}
										value={petData.overview || ''}
									/>

								</div>

								<div className={style.MainDropdownWrapper}>
									<MainDropdown
										DropdownTitle='Pet Role'
										dataArray={Roles}
										change={handleRoleChange}
										selectedOption={petData.role || ''}
									/>

									<MainDropdown
										DropdownTitle='Pet Class'
										dataArray={Classes}
										change={handleClassChange}
										selectedOption={petData.class || ''}
									/>

									<MainDropdown
										DropdownTitle='Pet Faction'
										dataArray={Factions}
										change={handleFactionChange}
										selectedOption={petData.faction || ''}
									/>

									<MainDropdown
										DropdownTitle='Pet Element'
										dataArray={Elements}
										change={handleElementChange}
										selectedOption={petData.element || ''}
									/>

									<MainDropdown
										DropdownTitle='Pet Rarity'
										dataArray={Rarity}
										change={handleRarityChange}
										selectedOption={petData.rarity || ''}
									/>

									<MainDropdown
										DropdownTitle='Pet Edition'
										dataArray={Editions}
										change={handleEditionChange}
										selectedOption={petData.edition || ''}
									/>

									{/* <MainDropdown
										DropdownTitle='Active Ability'
										dataArray={ActiveAbilities}
										change={handleActiveAbilityChange}
										selectedOption={petData.activeAbility || ''}
									/>

									<MainDropdown
										DropdownTitle='Passive Ability'
										dataArray={PassiveAbilities}
										change={handlePassiveAbilityChange}
										selectedOption={petData.passiveAbility || ''}
									/> */}
								</div>
							</div>


							<div>
								<WikiText
									TitleText='Pet Wiki'
									change={handleWikiTextChange}
									value={petData.wikiText || ''}
								/>
							</div>
						</div>
				}
			</div>
		</div>
	)
}

export default AdminEditHero;