import {
	toast
} from 'react-toastify';
import {
	isAdmin
} from '../../src/utils/userUtils';
import axios from 'axios';

const handleRequest = async (
	dataObj,
	user,
	userId,
	endpoint,
	method,
	successMessage,
	navigate,
	navigateRoute
) => {
	try {
		console.log('User ID:', userId); // Log the user ID
		console.log('Method:', method); // Log the method

		// Check if the user is an admin
		const userIsAdmin = await isAdmin(userId);

		console.log('User Is Admin:', userIsAdmin); // Log whether the user is an admin

		// If the user is not an admin, show an error
		if (!userIsAdmin) {
			console.log('User is not an admin. Access denied.'); // Log access denied
			toast.error('You are not authorized to perform this action.');
			return;
		}

		// Check for missing data
		const isDataMissing = Object.values(dataObj).some((value) => value === '' || value === undefined);

		console.log('Data Missing:', isDataMissing); // Log whether data is missing

		if (isDataMissing) {
			console.log('Some fields are missing. Request canceled.'); // Log missing fields
			toast.error('Some fields are missing');
			return;
		}

		// Make the HTTP request based on the specified method
		let response;

		if (method === 'post') {
			response = await axios.post(endpoint, {
				dataObj,
				userId
			});
		} else if (method === 'patch') {
			response = await axios.patch(endpoint, {
				dataObj,
				userId
			});
		} else if (method === 'delete') {
			console.log('Running delete'); // Log that delete block is executed
			response = await axios.delete(endpoint, {
				data: {
					userId: user.id
				}
			});
		}

		console.log('Response Status:', response.status); // Log the response status

		if (response.status === 200) {
			console.log('Request succeeded.'); // Log success
			toast.success(successMessage);
			if (navigate) {
				navigate(navigateRoute);
			}
		} else {
			console.log('Request failed.'); // Log request failure
			toast.error('Request failed');
		}
	} catch (err) {
		console.error('An error occurred:', err); // Log the error
		toast.error('An error occurred');
	}
};

export default handleRequest;