import React, { useEffect, useState } from "react";
import axios from "axios";
import style from './Dungeons.module.css';
import { Link } from "react-router-dom";
import DungeonCard from "../../components/DungeonCard/DungeonCard";
import Spinner from '../../components/Spinner/Spinner';


const API_URL = process.env.REACT_APP_BACKEND_API;

const Dungeons = () => {
	const [dungeons, setDungeons] = useState(null)

	const getDungeons = () => {
		axios.get(`${API_URL}/dungeons`)
			.then(res => setDungeons(res.data))
	}

	useEffect(() => {
		getDungeons()
	}, [])

	return (
		<div>
			<h2 className='PageHeading'>Dungeons</h2>
			{/* <Filter /> */}

			<div className={style.DungeonsWrapper}>
				{
					dungeons ? dungeons.map(
						dungeon =>
							<Link to={`/dungeon-levels/${dungeon._id}`}>
								<DungeonCard
									color='#316976'
									image={dungeon.imageURL}
									dungeonName={dungeon.name}
									description={dungeon.description}
								/>
							</Link>
					) : <div className='SpinnerContainer'>
						<Spinner />
					</div>
				}
			</div>
		</div>
	)
}

export default Dungeons;