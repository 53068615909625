import React from 'react';
import style from './ToolTip.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ToolTip = props => {
	return (
		<div className={style.Tooltip}>
			<Link to={props.viewLink}>
				<button className={style.ToolTipOption}>
					View
				</button>
			</Link>

			<Link to={props.editLink}>
				<button
					className={style.ToolTipOption}>
					Edit
				</button>
			</Link>

			<button
				onClick={() => props.delete()}
				className={style.ToolTipOption}>
				Delete
			</button>
		</div>
	)
}

export default ToolTip;