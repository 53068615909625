import React from 'react';
import style from './SquadOverview.module.css';

const SquadOverview = props => {
	const { selectedSquad, Attribute } = props;

	const getAttributeCount = () => {
		const attributeCountMap = new Map();
		selectedSquad.forEach(guardian => {
			const attributeValue = guardian[Attribute];
			if (attributeValue) {
				if (attributeCountMap.has(attributeValue)) {
					attributeCountMap.set(attributeValue, attributeCountMap.get(attributeValue) + 1);
				} else {
					attributeCountMap.set(attributeValue, 1);
				}
			}
		});
		return attributeCountMap;
	};

	return (
		<div className={style.SquadOverview}>
			<div className={style.SquadRoleWrapper}>
				<img className={style.SquadRoleIcon} src={props.Icon} alt="" />
				<span className={style.SquadRoleText}>{props.Role}</span>
			</div>

			<div className={style.SquadStatWrapper}>
				{getAttributeCount().size > 0 ? (
					Array.from(getAttributeCount()).map(([value, count]) => (
						<span key={value} className={style.SquadStat}>{value} x{count}</span>
					))
				) : (
					<span className={style.SquadStat}>None</span>
				)}
			</div>
		</div>
	);
};

export default SquadOverview;
