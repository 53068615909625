import React from "react";
import style from './MainInput.module.css';

const MainInput = props => {
	const inputClasses = `${style.MainInput} ${props.value ? "" : style.EmptyInput}`;

	return (
		<div className={style.MainInputWrapper}>
			<span>{props.TitleText}</span>
			<input
				type="text"
				className={inputClasses}
				placeholder={props.placeholder}
				onChange={props.change}
				value={props.value}
			/>
		</div>
	);
};

export default MainInput;