import React from 'react';
import style from './SquadItem.module.css';
import RoleIcon from '../../assets/role_icon.svg';
import ElementIcon from '../../assets/element_icon.svg';
import ClassIcon from '../../assets/class_icon.svg';
import FactionIcon from '../../assets/faction_icon.svg';
// import GuardianImage from '../../assets/lia.png';
import deleteIcon from '../../assets/delete-icon.svg'

const SquadItem = props => {
	return (
		<div className={style.SquadItem}
		>
			<div className={style.SquadGuardianInfo}>
				<div className={style.SquadGuardianImageWrapper}>
					<img
						className={style.SquadGuardianImage}
						src={props.guardianImage}
						alt=""
					/>
				</div>

				<div className={style.SquadAttributesWrapper}>
					<span className={style.SquadGuardianName}>{props.guardianName}</span>

					<div className={style.SquadAttributesTextContainer}>
						<div className={style.SquadAttributes}>
							<img
								src={RoleIcon}
								className={style.SquadAttributeIcon}
								alt=""
							/>
							<div className={style.SquadAttributesTextWrapper}>
								<span className={style.SquadRole}>Role</span>
								<span className={style.SquadRoleText}>{props.guardianRole}</span>
							</div>
						</div>

						<div className={style.SquadAttributes}>
							<img
								src={ElementIcon}
								className={style.SquadAttributeIcon}
								alt="" />
							<div className={style.SquadAttributesTextWrapper}>
								<span className={style.SquadRole}>Element</span>
								<span className={style.SquadRoleText}>{props.guardianElement}</span>
							</div>
						</div>

						<div className={style.SquadAttributes}>
							<img
								src={ClassIcon}
								className={style.SquadAttributeIcon}
								alt="" />
							<div className={style.SquadAttributesTextWrapper}>
								<span className={style.SquadRole}>Class</span>
								<span className={style.SquadRoleText}>{props.guardianClass}</span>
							</div>
						</div>

						<div className={style.SquadAttributes}>
							<img
								src={FactionIcon}
								className={style.SquadAttributeIcon}
								alt="" />
							<div className={style.SquadAttributesTextWrapper}>
								<span className={style.SquadRole}>Faction</span>
								<span className={style.SquadRoleText}>{props.guardianFaction}</span>
							</div>
						</div>
					</div>
				</div>

			</div>
			{/* <div className={style.space}/> */}

			<img
				src={deleteIcon}
				alt=""
				className={style.SquadGuardianDeleteIcon}
				onClick={props.Click}
			/>
		</div>
	)
}

export default SquadItem;