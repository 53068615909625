import React, { useState, useEffect } from 'react';
import style from './DungeonWiki.module.css';
import axios from 'axios';
import DungeonImage from '../../assets/dungeons-image.png';
import SquadPowerBar from '../../components/SquadPowerBar/SquadPowerBar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";


const DungeonWiki = props => {
	const [dungeon, setDungeon] = useState(null)
	const { dungeonID, dungeonLevelID } = useParams()
	const API_URL = process.env.REACT_APP_BACKEND_API;

	const getGuardianInfo = () => {
		axios.get(`${API_URL}/dungeons/${dungeonID}/levels/${dungeonLevelID}`)
			.then(res => setDungeon(res.data))
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getGuardianInfo()
		console.log('test')
		console.log(dungeonID, dungeonLevelID)
	}, [])

	return (

		dungeon ?
			<div className={style.DungeonContainer}>
				<div className={style.DungeonInfoWrapper}>
					<div className={style.DungeonInfo}>
						<div className={style.DungeonNameWrapper}>
							<h1 className={style.DungeonName}>{dungeon.level}</h1>
						</div>

						<span>Recommended Squad Power</span>
						<SquadPowerBar 
							power = {dungeon.squadPower}
						/>
					</div>

					<div className={style.DungeonImageContainer}>
						<img
							src={dungeon.imageURL}
							alt=""
							className={style.DungeonImage}
						/>
					</div>
				</div>

				<div className={style.WikiTextContainer}>
					<ReactMarkdown>
						{dungeon.wikiText}
					</ReactMarkdown>
				</div>
			</div>
			:
			<span>Loading</span>

	)
}

export default DungeonWiki;