import React from 'react';
import style from './WikiText.module.css';

const WikiText = props => {
	const inputClasses = `${style.WikiTextInput} ${props.value ? "" : style.EmptyInput}`;

	return (
		<div className={style.WikiTextWrapper}>
			<span>{props.TitleText}</span>
			<textarea
				type="text"
				className={inputClasses}
				onChange={props.change}
				value={props.value}
			/>
		</div>
	)
}

export default WikiText;