import React, { useState } from 'react';
import style from './AdminAddPet.module.css';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { isAdmin } from '../../utils/userUtils';
import { useUser } from '@clerk/clerk-react';
import handleRequest from '../../utils/inputCheck'

import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import MainInput from '../../components/MainInput/MainInput';
import MainDropdown from '../../components/MainDropdown/MainDropdown';
import WikiText from '../../components/WikiText/WikiText';
import AdminPetPreviewPage from '../AdminPetPreviewPage/AdminPetPreviewPage';
import FileUpload from '../../components/FileUpload/FileUpload';

import { Roles } from '../../content/attributes';
import { Classes } from '../../content/attributes';
import { Elements } from '../../content/attributes';
import { petFactions } from '../../content/attributes';
import { Rarity } from '../../content/attributes';
import { Editions } from '../../content/attributes';
// import { PassiveAbilities } from '../../content/attributes';
// import { ActiveAbilities } from '../../content/attributes';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminAddPet = () => {
	const navigate = useNavigate()
	const { user } = useUser()

	const [showPreview, setShowPreview] = useState(false)
	const [petData, setPetData] = useState(
		{
			name: '',
			imageURL: '',
			description: '',
			price: 0,
			role: Roles[0].name,
			class: Classes[0].name,
			faction: petFactions[0].name,
			element: Elements[0].name,
			rarity: Rarity[0].name,
			edition: Editions[0].name,
			// activeAbility: '',
			// passiveAbility: '',
			overview: '',
			wikiText: ''
		}
	)

	const handlePost = async (e) => {
		toast('adding pet');
		handleRequest(
			petData,
			user,
			user.id,
			`${API_URL}/pets`,
			'post',
			'pet added',
			navigate,
			'/admin-pets'
		);
		console.log(user.id)
	};



	const handleNameChange = event => {
		setPetData({ ...petData, name: event.target.value });
	}

	const handleImageLinkChange = event => {
		setPetData({ ...petData, imageURL: event.target.value });
	}

	const handleDescriptionChange = event => {
		setPetData({ ...petData, description: event.target.value });
	}

	const handleRoleChange = selectedValue => {
		setPetData({ ...petData, role: selectedValue });
	}

	const handleClassChange = selectedValue => {
		setPetData({ ...petData, class: selectedValue });
	}

	const handleFactionChange = selectedValue => {
		setPetData({ ...petData, faction: selectedValue });
	}

	const handleElementChange = selectedValue => {
		setPetData({ ...petData, element: selectedValue });
	}

	const handleRarityChange = selectedValue => {
		setPetData({ ...petData, rarity: selectedValue });
	}

	const handleEditionChange = selectedValue => {
		setPetData({ ...petData, edition: selectedValue });
	}

	const handleOverviewChange = event => {
		setPetData({ ...petData, overview: event.target.value });
	}

	const handleWikiTextChange = event => {
		setPetData({ ...petData, wikiText: event.target.value });
	}

	const handleSetUploadLink = (imageLink) => {
		setPetData({ ...petData, imageURL: imageLink });
	};


	return (
		<div>
			<div className='AdminPage'>
				<AdminSideBar />

				<div className='AdminContentWrapper'>
					<div className='AdminTopBar'>
						<h2>Add Pet </h2>
						<div className='AdminButtonWrapper'>
							<span onClick={
								() => setShowPreview(!showPreview)
							}>Preview</span>

							<PrimaryCTA
								buttonText='Add Pet'
								buttonClick={handlePost}
							/>
						</div>
					</div>

					{
						showPreview ?
							<AdminPetPreviewPage
								petData={petData}
							/>
							:
							<div className={style.PageContent}>
								<div className={style.PageInputContainer}>
									<div className={style.InputsWrapper}>
										<MainInput
											TitleText='Pet Name'
											palceholder='Pet name'
											change={handleNameChange}
											value={petData.name}
										/>

										<MainInput
											TitleText='Pet Image Link'
											palceholder='Pet Image Link'
											change={handleImageLinkChange}
											value={petData.imageURL}
										/>

										<FileUpload
											onUploadComplete={handleSetUploadLink}
										/>

										<MainInput
											TitleText='Pet Description'
											palceholder='Pet Description'
											change={handleDescriptionChange}
											value={petData.description}
										/>

										<MainInput
											TitleText='Pet Overview'
											palceholder='Pet Overview'
											change={handleOverviewChange}
											value={petData.overview}
										/>
									</div>

									<div className={style.MainDropdownWrapper}>
										<MainDropdown
											DropdownTitle='Pet Role'
											dataArray={Roles}
											change={handleRoleChange}
										/>
										<MainDropdown
											DropdownTitle='Pet Class'
											dataArray={Classes}
											change={handleClassChange}
										/>
										<MainDropdown
											DropdownTitle='Pet Faction'
											dataArray={petFactions}
											change={handleFactionChange}
										/>
										<MainDropdown
											DropdownTitle='Pet Element'
											dataArray={Elements}
											change={handleElementChange}
										/>
										<MainDropdown
											DropdownTitle='Pet Rarity'
											dataArray={Rarity}
											change={handleRarityChange}
										/>
										<MainDropdown
											DropdownTitle='Pet Edition'
											dataArray={Editions}
											change={handleEditionChange}
										/>
										{/* <MainDropdown
											DropdownTitle='Active Ability'
											dataArray={ActiveAbilities}
											change={handleActiveAbilityChange}
										/>
										<MainDropdown
											DropdownTitle='Passive Ability'
											dataArray={PassiveAbilities}
											change={handlePassiveAbilityChange}
										/> */}
									</div>
								</div>


								<div>
									<WikiText
										TitleText='Pet Wiki'
										change={handleWikiTextChange}
										value={petData.wikiText}
									/>
								</div>
							</div>
					}

				</div>
			</div>
		</div>
	)
}

export default AdminAddPet;