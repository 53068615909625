import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import style from './GuardianWiki.module.css';

import axios from 'axios';

import passiveIcon from '../../assets/passive_icon.svg';
import activeIcon from '../../assets/active_icon.svg';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Spinner from '../../components/Spinner/Spinner';

import { Roles, Classes, Elements, Factions, Rarity } from '../../content/attributes';
import NAIcon from '../../assets/icons/naIcon.svg'
import EditionIcon from '../../assets/role_icon.svg'
import RarityIcon from '../../assets/rarity_icon.svg'


const Guardian = () => {
	const { guardianID } = useParams()
	const API_URL = process.env.REACT_APP_BACKEND_API;
	const [guardian, setGuardian] = useState({})
	const [isLoading, setLoading] = useState(true)

	const getGuardianInfo = () => {
		setLoading(true); // Set loading state to true

		axios.get(`${API_URL}/guardians/${guardianID}`)
			.then(res => setGuardian(res.data))
			.catch(err => console.log(err))
			.finally(() => setLoading(false)); // Set loading state to false in the finally block
	}

	useEffect(() => {
		getGuardianInfo()
	}, [])

	const selectedRole = Roles.find(role => role.name === guardian.role)
	const roleIcon = selectedRole ? selectedRole.icon : NAIcon

	const selectedClass = Classes.find(heroClass => heroClass.name === guardian.class)
	const classIcon = selectedClass ? selectedClass.icon : NAIcon

	const selectedElement = Elements.find(element => element.name === guardian.element)
	const elementIcon = selectedElement ? selectedElement.icon : NAIcon

	const selectedFaction = Factions.find(heroClass => heroClass.name === guardian.class)
	const factionIcon = selectedFaction ? selectedFaction.icon : NAIcon

	const selectedRarity = Rarity.find(rarity => rarity.name === guardian.rarity)
	const RarityIcon = selectedRarity ? selectedRarity.icon : NAIcon


	return (
		isLoading ? <span>Loading</span> :
			<div className={style.GuardianContainer}>
				<div className={style.GuardianInfoWrapper}>
					<div className={style.GuardianInfo}>
						<div className={style.GuardianNameWrapper}>
							<h1 className={style.GuardianName}>{guardian.name}</h1>
							<h4 className={style.GuardianDescription}>{guardian.description}</h4>
						</div>


						<div className={style.AttributesWrapper}>
							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={roleIcon}
										className={style.AttributeIcon}
										alt="" />
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Role</span>
										<span className={style.AttributeText}>{guardian.role}</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={classIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Class</span>
										<span className={style.AttributeText}>{guardian.class}</span>
									</div>
								</div>
							</div>

							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={factionIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Faction</span>
										<span className={style.AttributeText}>{guardian.faction}</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={elementIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Element</span>
										<span className={style.AttributeText}>{guardian.element}</span>
									</div>
								</div>
							</div>

							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={RarityIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Rarity</span>
										<span className={style.AttributeText}>{guardian.rarity}</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={EditionIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Edition</span>
										<span className={style.AttributeText}>{guardian.edition}</span>
									</div>
								</div>
							</div>
						</div>

						<div className={style.OverviewContainer}>
							<h2 className={style.OverviewHeading}>Overview</h2>
							<p className={style.OverviewText}>{guardian.overview}</p>
						</div>

					</div>

					<div className={style.GuardianImageContainer}>
						<img
							src={guardian.imageURL}
							alt=""
							className={style.GuardianImage}
						/>
					</div>
				</div>

				<div className={style.AbilitiesWrapper}>
					<div className={style.AbilityContainer}>
						<img src={passiveIcon} alt="" className={style.AbilityIcon} />
						<div className={style.AbilityContent}>
							<span className={style.AbilityHeading}>Passive ability</span>
							<span className={style.AbilityText}>{guardian.passiveAbility}</span>
							<span className={style.AbilityDescription}>
								{guardian.activeAbilityDescription}
							</span>
						</div>
					</div>

					<div className={style.AbilityContainer}>
						<img src={activeIcon} alt="" className={style.AbilityIcon} />
						<div className={style.AbilityContent}>
							<span className={style.AbilityHeading}>Ultimate ability</span>
							<span className={style.AbilityText}>{guardian.activeAbility}</span>
							<span className={style.AbilityDescription}>
								{guardian.passiveAbilityDescription}
							</span>
						</div>
					</div>
				</div>

				<div className={style.WikiTextContainer}>
					<ReactMarkdown>
						{guardian.wikiText}
					</ReactMarkdown>
					<div className={style.WikiTextWrapper}>
					</div>
				</div>
			</div>
	)
}

export default Guardian;