import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from './DungeonLevelCard.module.css'
import SquadPowerBar from '../SquadPowerBar/SquadPowerBar';

import elipsis from '../../assets/elipsis.svg';
import ToolTip from '../ToolTip/ToolTip';

const DungeonLevelCard = props => {
	const [openToolTip, setOpenToolTip] = useState(false);


	// const deleteGuardian = () => {
	// 	axios.delete(`${API_URL}/guardians/${props.guardianID}`)
	// 		.then(res => console.log(res.data))
	// 		.catch(err => err)
	// }

	return (
		<div className={style.DungeonLevelCard} style={{ backgroundImage: `url(${props.imageURL})` }}>
			<div className={style.CardOverlay} />
			<div className={style.DungeonLevelAttributesWrapper}>
				<div className={style.DungeonLevelAttributes}>
					<div className={style.DungeonLevelNameWrapper}>
						<span className={style.DungeonLevelName}>{props.level}</span>
						{
							props.editable ?
								<div className={style.Elipsis}>
									<div
										className={style.ElipsisWrapper}
										onClick={() => setOpenToolTip(!openToolTip)}
									>
										<img
											src={elipsis}
											alt=""
											className={style.ElipsisIcon} />
									</div>

									{
										openToolTip ?
											<ToolTip
												viewLink={`/dungeon/${props.dungeonID}/${props.dungeonLevelID}`}
												editLink={`/edit-dungeon-level/${props.dungeonID}/${props.dungeonLevelID}`}
												delete={props.delete}
											/>
											: null
									}
								</div>
								: null
						}
					</div>
					<span className={style.DungeonRecommendedText}>Recommended Squad Power</span>
					<SquadPowerBar
						power={props.power}
					/>
				</div>
			</div>
		</div>
	);
}

export default DungeonLevelCard;