import React from 'react';
import style from './AdminSideBar.module.css';
import { Link } from 'react-router-dom';

const AdminSideBar = () => {
	return (
		<div className={style.AdminSideBar}>
			<div className={style.AdminSideBarWrapper}>
				<Link to='/admin-heroes'>
					<button className={style.AdminSideBarButton}>Heroes</button>
				</Link>

				<Link to='/admin-pets'>
					<button className={style.AdminSideBarButton}>Pets</button>
				</Link>

				<Link to='/admin-dungeons'>
					<button className={style.AdminSideBarButton}>Dungeons</button>
				</Link>

				<Link to='/admin-users'>
					<button className={style.AdminSideBarButton}>Users</button>
				</Link>
			</div>
		</div>
	)
}

export default AdminSideBar;