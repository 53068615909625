import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import style from './GuardianCard.module.css';
import ToolTip from '../ToolTip/ToolTip';

import elipsis from '../../assets/elipsis.svg';

import { Roles, Classes } from '../../content/attributes';
import NAIcon from '../../assets/icons/naIcon.svg'

const GuardianCard = props => {
	const { guardianID } = useParams()

	const selectedRole = Roles.find(role => role.name === props.guardianRole)
	const roleIcon = selectedRole ? selectedRole.icon : NAIcon

	const selectedClass = Classes.find(heroClass => heroClass.name === props.guardianClass)
	const classIcon = selectedClass ? selectedClass.icon : NAIcon

	const guardianCardWrapperStyle = {
		backgroundImage: 'url(' + props.guardianImageURL + ')'
	}

	const [openToolTip, setOpenToolTip] = useState(false);

	return (
		<div className={style.GuardianCardWrapper}
			style={guardianCardWrapperStyle}
		>
			<div
				// src={ariaImage}
				alt=""
				className={style.GuardianImage}
			/>
			<div className={style.GuardianCardInfo}>
				<div className={style.Guardian}>
					<div className={style.GuardianCardInfoWrapper}>
						<div className={style.GuardianNameWrapper}>
							<span className={style.GuardianName}>{props.guardianName}</span>
							<span className={style.GuardianTagline}>{props.guardianDescription}</span>
						</div>
						{
							props.editable ?
								<div className={style.Elipsis}>
									<div
										className={style.ElipsisWrapper}
										onClick={() => setOpenToolTip(!openToolTip)}
									>
										<img
											src={elipsis}
											alt=""
											className={style.ElipsisIcon} />
									</div>

									{
										openToolTip ?
											<ToolTip
												viewLink={`/guardian/${props.guardianID}`}
												editLink={`/edit-hero/${props.guardianID}`}
												delete={props.delete}
											/>
											: null

									}
								</div>
								: null
						}
					</div>

					<div className={style.GuardianAttributeWrapper}>
						<div className={style.GuardianAttribute}>
							<img
								src={roleIcon}
								alt=""
								className={style.AttributeIcon} />

							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>Role
								</span>
								<span
									className={style.GuardianAttributeText}>{props.guardianRole}
								</span>
							</div>
						</div>

						<div className={style.GuardianAttribute}>
							<img
								src={classIcon}
								alt=""
								className={style.AttributeIcon}
							/>
							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>Class</span>
								<span className={style.GuardianAttributeText}>{props.guardianClass}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GuardianCard;