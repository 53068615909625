import React, { useState } from 'react';
import style from './AdminAddDungeon.module.css';
import { Link, useNavigate } from 'react-router-dom';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import MainInput from '../../components/MainInput/MainInput';
import DungeonCard from '../../components/DungeonCard/DungeonCard';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';

import FileUpload from '../../components/FileUpload/FileUpload';

import MainDropdown from '../../components/MainDropdown/MainDropdown';
import DungeonLevelCard from '../../components/DungeonLevelCard/DungeonLevelCard';
import { Elements } from '../../content/attributes'
import { Tiers } from '../../content/dungeonAttribute';
import handleRequest from '../../utils/inputCheck';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminAddDungeon = () => {
	const navigate = useNavigate()
	const { user } = useUser()

	const [dungeonData, setDungeonData] = useState(
		{
			name: '',
			imageURL: '',
			description: '',
			levelNumber: 0,
			tier: Tiers[0].name,
			element: Elements[0].name,
			dungeonLevels: []
		}
	)

	const handleNameChange = event => {
		setDungeonData({ ...dungeonData, name: event.target.value });
	}

	const handleImageLinkChange = event => {
		setDungeonData({ ...dungeonData, imageURL: event.target.value });
	}

	const handleDescriptionChange = event => {
		setDungeonData({ ...dungeonData, description: event.target.value });
	}


	const handleTierChange = selectedValue => {
		setDungeonData({ ...dungeonData, tier: selectedValue });
	}

	const handleElementChange = selectedValue => {
		setDungeonData({ ...dungeonData, element: selectedValue });
	}

	const handleSetUploadLink = (imageLink) => {
		setDungeonData({ ...dungeonData, imageURL: imageLink });
	};

	const handlePost = async (e) => {
		// e.preventDefault();
		toast('adding dungeon');
		handleRequest(
			dungeonData,
			user,
			user.id,
			`${API_URL}/dungeons`,
			'post',
			'dungeon added',
			navigate,
			'/admin-dungeons'
		)
	};

	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<div className='AdminTopBar'>
					<span>Add dungeon</span>
					<div>
						<PrimaryCTA
							buttonText='Save Dungeon'
							buttonClick={handlePost}
						/>
					</div>
				</div>

				<div className={style.AddDungeonContent}>
					<div className={style.AddDungeonInputWrapper}>
						<div className={style.MainInputWrapper}>
							<MainInput
								TitleText='Dungeon name'
								placeholder='Dungeon name'
								change={handleNameChange}
								value={dungeonData.name}
							/>

							<MainInput
								TitleText='Dungeon image link'
								placeholder='Dungeon image link'
								change={handleImageLinkChange}
								value={dungeonData.imageURL}
							/>

							<FileUpload
								onUploadComplete={handleSetUploadLink}
							/>

							<MainInput
								TitleText='Dungeon Description'
								placeholder='Dungeon description'
								change={handleDescriptionChange}
								value={dungeonData.description}
							/>
						</div>

						<div className={style.MainDropdownWrapper}>
							<MainDropdown
								DropdownTitle='Dungeon Element'
								dataArray={Elements}
								change={handleElementChange}
							/>

							<MainDropdown
								DropdownTitle='Dungeon Tier'
								dataArray={Tiers}
								change={handleTierChange}
							/>
						</div>
					</div>
				</div>
				{/* <DungeonCard /> */}
			</div>

		</div>
	)
}

export default AdminAddDungeon;