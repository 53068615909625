export const Tiers = [{
		name: '1'
	},
	{
		name: '2'
	},
	{
		name: '3'
	},
	{
		name: '4'
	},
	{
		name: '5'
	},
	{
		name: '6'
	},
	{
		name: '7'
	},
]