import React from 'react';
import style from '../DungeonWiki/DungeonWiki.module.css';
// import DungeonImage from '../../assets/dungeons-image.png';
import SquadPowerBar from '../../components/SquadPowerBar/SquadPowerBar';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import dungeonIcon from '../../assets/dungeonIcon.svg';
import elementIcon from '../../assets/element_icon.svg';
import tierIcon from '../../assets/tierIcon.svg';

const AdminDungeonPreviewPage = props => {
	return (
		<div className={style.DungeonContainer}>
			<div className={style.DungeonInfoWrapper}>
				<div className={style.DungeonInfo}>
					<div className={style.DungeonNameWrapper}>
						<h1 className={style.DungeonName}>
							{props.dungeonData.level}
						</h1>
					</div>

					<span>Recommended Squad Power</span>
					<SquadPowerBar
						power = {props.dungeonData.squadPower}
					 />
				</div>

				<div className={style.DungeonImageContainer}>
					<img
						src={props.dungeonData.imageURL}
						alt=""
						className={style.DungeonImage}
					/>
				</div>
			</div>

			<div className={style.WikiTextContainer}>
				<ReactMarkdown>
					{props.dungeonData.wikiText}
				</ReactMarkdown>
			</div>
		</div>
	)
}

export default AdminDungeonPreviewPage;