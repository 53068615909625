import React, { useState, useEffect } from 'react';
import style from './MobileNavBar.module.css'
import SecondaryCTA from '../Buttons/SecondaryCTA/SecondaryCTA';
import hambugerIcon from '../../assets/hamburgerIcon.svg'
import Logo from '../../assets/gog-logo.svg'
import CrossIcon from '../../assets/cross-icon.svg'
import { Link } from 'react-router-dom';
import { RedirectToSignIn, SignedOut, SignIn, useUser, UserButton } from "@clerk/clerk-react";

const MobileNavBar = () => {
	const { user } = useUser()
	const [NavIsOpen, setNavIsOpen] = useState(false)

	useEffect(() => {
		// Handle body scrolling when the navbar is opened or closed
		if (NavIsOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [NavIsOpen]);

	const closeNav = () => {
		setNavIsOpen(false)
	}

	const NavBarContent = () => {
		return (
			<div className={style.NavBarContent}>
				<div className={style.NavBarContentWrapper}
				>
					<div className={style.NavBarContentContainer}>
						<Link to='/home'>
							<span className={style.NavBarContentText}
								onClick={closeNav}
							>
								Home</span>
						</Link>

						<Link to='/guardians'
							onClick={closeNav}
						>
							<span className={style.NavBarContentText}>Heroes</span>
						</Link>

						<Link to='/pets'
							onClick={closeNav}
						>
							<span className={style.NavBarContentText}>Pets</span>
						</Link>

						<Link to='/dungeons'
							onClick={closeNav}
						>
							<span className={style.NavBarContentText}>Dungeons</span>
						</Link>

						<Link to='/team-composition'
							onClick={closeNav}
						>
							<span className={style.NavBarContentText}>Team Composition</span>
						</Link>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={style.MobileNavbar}>
			<div className={style.MobileNavBarContainer}>
				<div className={style.MobileNavBarWrapper}>
					<div className={style.LogoContainer}>
						<Link to='home'>
							<img
								src={Logo}
								alt=""
								className={style.Logo}
							/>
						</Link>

						{
							NavIsOpen ?
								<img src={CrossIcon}
									alt=""
									className={style.NavHamburgerIcon}
									onClick={() => setNavIsOpen(!NavIsOpen)}
								/>
								:
								<img src={hambugerIcon}
									alt=""
									className={style.NavHamburgerIcon}
									onClick={() => setNavIsOpen(!NavIsOpen)}
								/>
						}

						<Link to='home'>
							{/* <span>Logo</span> */}
						</Link>
					</div>

					<div>
						{
							user ?
								<div className={style.NavGreeting}>
									<span className={style.NavUserName}>Hi, {user.username}!</span>
									<UserButton />
								</div>
								:
								<Link to='/login'>
									<SecondaryCTA
										buttonText='Login'
									/>
								</Link>
						}
					</div>
				</div>

				{
					NavIsOpen ? <NavBarContent /> : null
				}
			</div>
		</div>
	)
}

export default MobileNavBar;