import React, { useState, useEffect } from 'react';
import style from './Home.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
// import { isAdmin } from '../src/utils/userUtils';
import { isAdmin } from '../../utils/userUtils';
import Spinner from '../../components/Spinner/Spinner';

import cyrusImage from '../../assets/cyrus.png'
import dungeonsImage from '../../assets/dungeons-image.png'
import petsImage from '../../assets/pets-image.png'
import teamImage from '../../assets/team-image.png'

import CategoryCard from '../../components/CategoryCard/CategoryCard';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';

const API_URL = process.env.REACT_APP_BACKEND_API;

const Home = () => {
	const [categoriesData, setCategoriesData] = useState([])
	const [isAdminCheck, setIsAdmin] = useState(false)
	const categoriesColors = ['#1D2637', '#2F2309', '#336E81', '#2C3C3A']
	const { user } = useUser()

	useEffect(() => {
		if (user) {
			setIsAdmin(async () => await isAdmin(user.id))
		}
	}, [user]);

	return (
		<div>
			<section className={style.HomeIntro}>
				<div className={style.HomeIntroWrapper}>
					<h1 className={style.HomeIntroHeading}>
						Guide Of Guardians
					</h1>
					<span className={style.HomeIntroText}>
						The #1 resource for all things Guild of Guardians
					</span>

					<Link to='/guardians'>
						<PrimaryCTA
							buttonText='Explore Heroes'
						/>
					</Link>

				</div>
			</section>

			<section className={style.HomeCategoryWrapper}>
				<h2 className='PageHeading'>Categories</h2>
				<div className={style.HomeCategory}>
					<Link to='/guardians'>
						<CategoryCard
							categoryName={'Heroes'}
							categoryDescription={'Explore each hero and learn about their role, class, element, faction, and special abilities.'}
							backgroundColor={'#0D1A4D'}
							categoryImage={cyrusImage}
						/>
					</Link>

					<Link to='/pets'>
						<CategoryCard
							categoryName={'Pets'}
							categoryDescription={'Explore pets and the passive benefits they provide.'}
							backgroundColor={'#51020A'}
							categoryImage={petsImage}
						/>
					</Link>


					<Link to='/dungeons'>
						<CategoryCard
							categoryName={'Dungeons'}
							categoryDescription={'Explore each dungeon and the unique levels within.'}
							backgroundColor={'#3D7E92'}
							categoryImage={dungeonsImage}
						/>
					</Link>

					<Link to='/team-composition'>
						<CategoryCard
							categoryName={'Team Synergies'}
							categoryDescription={'Explore team synergies and the passive benefits they provide.'}
							backgroundColor={'#8E481B'}
							categoryImage={teamImage}
						/>
					</Link>
				</div>
			</section>

			<footer>
				© 2023 | Meta Moon Gaming
			</footer>
		</div>
	)
}

export default Home;