import React from 'react';
import style from './CategoryCard.module.css';

const CategoryCard = props => {
	return (
		<div
			className={style.CategoryCardWrapper}
			style={
				{
					backgroundColor: props.backgroundColor
				}
			}
		>
			<div className={style.CategoryCard}>
				<img src={props.categoryImage} alt="" className={style.CategoryImage} />
				<div className={style.CategoryInfo}>
					<span className={style.CategoryName}>{props.categoryName}</span>
					<span className={style.CategoryDescription}>
						{props.categoryDescription}
					</span>
				</div>
			</div>
		</div>
	)
};

export default CategoryCard