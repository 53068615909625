import React, { useState, useContext } from 'react';
import '../AdminPage/Admin.css'
import style from './AdminAddHero.module.css';
import handleRequest from '../../utils/inputCheck';

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { toast } from 'react-toastify';
import { isAdmin } from '../../utils/userUtils';

import AdminSideBar from './../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import MainInput from '../../components/MainInput/MainInput';
import WikiText from '../../components/WikiText/WikiText';
import MainDropdown from '../../components/MainDropdown/MainDropdown';
import AdminHeroPreviewPage from '../AdminHeroPreviewPage/AdminHeroPreviewPage';
import FileUpload from '../../components/FileUpload/FileUpload';

import { Roles } from '../../content/attributes';
import { Classes } from '../../content/attributes';
import { Elements } from '../../content/attributes';
import { Factions } from '../../content/attributes';
import { Rarity } from '../../content/attributes';
import { Editions } from '../../content/attributes';

import { UserContext } from '../../contexts/UserContext';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminAddHero = () => {
	const navigate = useNavigate()
	const { user } = useUser()

	const [showPreview, setShowPreview] = useState(false)
	const [heroData, setHeroData] = useState(
		{
			name: '',
			imageURL: '',
			description: '',
			price: 0,
			role: Roles[0].name,
			class: Classes[0].name,
			faction: Factions[0].name,
			element: Elements[0].name,
			rarity: Rarity[0].name,
			edition: Editions[0].name,
			activeAbility: '',
			activeAbilityDescription: '',
			passiveAbility: '',
			passiveAbilityDescription: '',
			overview: '',
			wikiText: '',
		}
	)

	const handlePost = async (e) => {
		toast('adding guardian');
		handleRequest(
			heroData,
			user,
			user.id,
			`${API_URL}/guardians`,
			'post',
			'guardian added',
			navigate,
			'/admin-heroes'
		);
	};

	// Input feild change handlers
	const handleNameChange = event => {
		setHeroData({ ...heroData, name: event.target.value });
	}

	const handleImageLinkChange = event => {
		setHeroData({ ...heroData, imageURL: event.target.value });
	}

	const handleDescriptionChange = event => {
		setHeroData({ ...heroData, description: event.target.value });
	}

	const handleRoleChange = selectedValue => {
		setHeroData({ ...heroData, role: selectedValue });
	}

	const handleClassChange = selectedValue => {
		setHeroData({ ...heroData, class: selectedValue });
	}

	const handleFactionChange = selectedValue => {
		setHeroData({ ...heroData, faction: selectedValue });
	}

	const handleElementChange = selectedValue => {
		setHeroData({ ...heroData, element: selectedValue });
	}

	const handleRarityChange = selectedValue => {
		setHeroData({ ...heroData, rarity: selectedValue });
	}

	const handleEditionChange = selectedValue => {
		setHeroData({ ...heroData, edition: selectedValue });
	}

	const handleActiveAbilityChange = event => {
		setHeroData({ ...heroData, activeAbility: event.target.value });
	}

	const handleActiveAbilityDescriptionChange = event => {
		setHeroData({ ...heroData, activeAbilityDescription: event.target.value });
	}

	const handlePassiveAbilityChange = event => {
		setHeroData({ ...heroData, passiveAbility: event.target.value });
	}

	const handlePassiveAbilityDescriptionChange = event => {
		setHeroData({ ...heroData, passiveAbilityDescription: event.target.value });
	}


	const handleOverviewChange = event => {
		setHeroData({ ...heroData, overview: event.target.value });
	}

	const handleWikiTextChange = event => {
		setHeroData({ ...heroData, wikiText: event.target.value });
	}

	const handleSetUploadLink = (imageLink) => {
		setHeroData({ ...heroData, imageURL: imageLink });
	};


	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<div className='AdminTopBar'>
					<h2>Add hero </h2>
					<div className='AdminButtonWrapper'>
						<span onClick={
							() => setShowPreview(!showPreview)
						}>{showPreview ? 'Hide Preview' : 'Show Preview'}</span>

						<PrimaryCTA buttonText='Add hero'
							buttonClick={handlePost}
						/>
					</div>
				</div>

				{
					showPreview ?
						<AdminHeroPreviewPage
							heroData={heroData}
						/>
						:
						<div className={style.HeroPageContent}>
							<div className={style.HeroPageInputContainer}>
								<div className={style.HeroInputsWrapper}>
									<MainInput
										TitleText='Hero Name'
										placeholder='Hero name'
										change={handleNameChange}
										value={heroData.name}
									/>

									<MainInput
										TitleText='Hero Image Link'
										placeholder='Hero Image Link'
										change={handleImageLinkChange}
										value={heroData.imageURL}
									/>

									<FileUpload
										onUploadComplete={handleSetUploadLink}
									/>

									<MainInput
										TitleText='Hero Tagline'
										placeholder='Hero Tagline'
										change={handleDescriptionChange}
										value={heroData.description}
									/>

									<MainInput
										TitleText='Hero Overview'
										placeholder='Hero Overview'
										change={handleOverviewChange}
										value={heroData.overview}
									/>
								</div>

								<div className={style.MainDropdownWrapper}>
									<MainDropdown
										DropdownTitle='Hero Role'
										dataArray={Roles}
										change={handleRoleChange}
										defaultValue={Roles[0]}
									/>
									<MainDropdown
										DropdownTitle='Hero Class'
										dataArray={Classes}
										change={handleClassChange}
									/>
									<MainDropdown
										DropdownTitle='Hero Faction'
										dataArray={Factions}
										change={handleFactionChange}
									/>
									<MainDropdown
										DropdownTitle='Hero Element'
										dataArray={Elements}
										change={handleElementChange}
									/>
									<MainDropdown
										DropdownTitle='Hero Rarity'
										dataArray={Rarity}
										change={handleRarityChange}
									/>
									<MainDropdown
										DropdownTitle='Hero Edition'
										dataArray={Editions}
										change={handleEditionChange}
									/>
								</div>
							</div>


							<div className={style.AbilitiesWrapper}>
								<div className={style.AbilityInput}>
									<MainInput
										TitleText='Ultimate Ability'
										placeholder='Ultimate Ability'
										change={handleActiveAbilityChange}
										value={heroData.activeAbility}
									/>

									<MainInput
										TitleText='Ultimate Ability Description'
										placeholder='Ultimate Ability Description'
										change={handleActiveAbilityDescriptionChange}
										value={heroData.activeAbilityDescription}
									/>
								</div>

								<div className={style.AbilityInput}>
									<MainInput
										TitleText='Passive Ability'
										placeholder='Passive Ability'
										change={handlePassiveAbilityChange}
										value={heroData.passiveAbility}
									/>

									<MainInput
										TitleText='Passive Ability Description'
										placeholder='Passive Ability Description'
										change={handlePassiveAbilityDescriptionChange}
										value={heroData.passiveAbilityDescription}
									/>
								</div>
							</div>


							<WikiText
								TitleText='Hero Wiki'
								change={handleWikiTextChange}
								value={heroData.wikiText}
							/>
						</div>
				}
			</div>
		</div>
	)
}

export default AdminAddHero;