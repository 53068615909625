import React, { useEffect, useState } from 'react';
// import style from './GuardianWiki.module.css';
import style from '../../pages/GuardianWiki/GuardianWiki.module.css'

import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { Roles, Classes, Elements, Factions, Rarity } from '../../content/attributes';
import NAIcon from '../../assets/icons/naIcon.svg'
import EditionIcon from '../../assets/role_icon.svg'


const GuardianWiki = () => {
	const { petID } = useParams()
	const API_URL = process.env.REACT_APP_BACKEND_API;
	const [pet, setGuardian] = useState({})

	const getGuardianInfo = () => {
		axios.get(`${API_URL}/pets/${petID}`)
			.then(res => setGuardian(res.data))
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getGuardianInfo()
	}, [])


	const selectedRole = Roles.find(role => role.name === pet.role)
	const roleIcon = selectedRole ? selectedRole.icon : NAIcon

	const selectedClass = Classes.find(heroClass => heroClass.name === pet.class)
	const classIcon = selectedClass ? selectedClass.icon : NAIcon

	const selectedElement = Elements.find(element => element.name === pet.element)
	const elementIcon = selectedElement ? selectedElement.icon : NAIcon

	const selectedFaction = Factions.find(heroClass => heroClass.name === pet.class)
	const factionIcon = selectedFaction ? selectedFaction.icon : NAIcon

	const selectedRarity = Rarity.find(rarity => rarity.name === pet.rarity)
	const RarityIcon = selectedRarity ? selectedRarity.icon : NAIcon


	return (
		!pet
			? <span>Loading</span>
			:
			<div className={style.GuardianContainer}>
				<div className={style.GuardianInfoWrapper}>
					<div className={style.GuardianInfo}>
						<div className={style.GuardianNameWrapper}>
							<h1 className={style.GuardianName}>{pet.name}</h1>
							<h4 className={style.GuardianDescription}>
								{pet.overview}
							</h4>
						</div>

						<div className={style.AttributesWrapper}>
							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={roleIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>
											Role</span>
										<span className={style.AttributeText}>
											{pet.role}
										</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={classIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Class</span>
										<span className={style.AttributeText}>
											{pet.class}
										</span>
									</div>
								</div>
							</div>

							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={factionIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Faction</span>
										<span className={style.AttributeText}>
											{pet.faction}
										</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={elementIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Element</span>
										<span className={style.AttributeText}>
											{pet.element}
										</span>
									</div>
								</div>
							</div>

							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={RarityIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Rarity</span>
										<span className={style.AttributeText}>{pet.rarity}</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={EditionIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Edition</span>
										<span className={style.AttributeText}>{pet.edition}</span>
									</div>
								</div>
							</div>
						</div>


						<div className={style.OverviewContainer}>
							<h2 className={style.OverviewHeading}>Overview</h2>
							<p className={style.OverviewText}>
								{pet.overview}
							</p>
						</div>

					</div>

					<div className={style.GuardianImageContainer}>
						<img
							src={pet.imageURL}
							alt=""
							className={style.GuardianImage}
						/>
					</div>
				</div>

				<div className={style.WikiTextContainer}>
					<ReactMarkdown>
						{pet.wikiText}
					</ReactMarkdown>
					<div className={style.WikiTextWrapper} />
				</div>
			</div>
	);
}

export default GuardianWiki;