import React from 'react';
import style from './FilterCategory.module.css';
import FilterItem from '../FilterItem/FilterItem';

const FilterCategory = props => {
	return (
		<div className={style.FilterCategoryWrapper}>
			<div className={style.FilterCategoryContainer}>
				<span className={style.FilterCategoryTitle}>{props.name}:</span>

				<div className={style.FilterOptions}>
					{
						props.itemsArray.map(item =>
							<FilterItem
								name={item.name}
								icon={item.icon}
								selectedOptions={props.selectedOptions}
								setSelectedOptions={props.setSelectedOptions}
								onItemClick={(itemName) => props.onItemSelect(props.name, itemName)} // Pass the item name and category name to the callback prop
							/>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default FilterCategory;