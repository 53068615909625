import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';
import handleRequest from '../../utils/inputCheck';

import style from './AdminDungeons.module.css';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import DungeonCard from '../../components/DungeonCard/DungeonCard';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminDungeons = () => {
	const [dungeons, setDungeons] = useState(null);
	const { user } = useUser()
	const getDungeons = () => {
		axios.get(`${API_URL}/dungeons`)
			.then(res => setDungeons(res.data))
	}

	// const deleteDungeon = (id) => {
	// 	axios
	// 		.delete(`${API_URL}/dungeons/${id}`)
	// 		.then(res => {
	// 			if (res.status === 200) {
	// 				toast.success('Dungeon deleted');
	// 				setDungeons(prevDungeons => prevDungeons.filter(dungeon => dungeon._id !== id));
	// 			} else {
	// 				toast.error('Failed to delete dungeon');
	// 			}
	// 		})
	// 		.catch(err => {
	// 			console.log(err);
	// 			toast.error('An error occurred');
	// 		});
	// };

	const deleteDungeon = async (id, userId) => {
		toast('deleting dungeon');
		handleRequest(
			{},
			user,
			userId,
			`${API_URL}/dungeons/${id}`,
			'delete',
			'dungeon deleted',
			null,
			null);
		setDungeons(prevDungeons => prevDungeons.filter(dungeon => dungeon._id !== id));
	};

	useEffect(() => {
		getDungeons()
	}, [])

	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<div className='AdminTopBar'>
					<span>Dungeons</span>
					<div>
						<Link to='/add-dungeon'>
							<PrimaryCTA buttonText='Add Dungeon'
								buttonClick={() => null}
							/>
						</Link>
					</div>
				</div>

				<div className={style.DungeonsWrapper}>
					{
						dungeons ? dungeons.map(
							dungeon =>
								<DungeonCard
									color='#316976'
									image={dungeon.imageURL}
									dungeonName={dungeon.name}
									description={dungeon.description}
									id={dungeon._id}
									delete={() => deleteDungeon(dungeon._id, user.id)}
									editable={true}
								/>
						) : <div className='SpinnerContainer'>
							<Spinner />
						</div>
					}
				</div>
			</div>

		</div>
	)
}

export default AdminDungeons;