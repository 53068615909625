import React, { useState, useEffect } from 'react';
import style from './MainDropdown.module.css';

const MainDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    if (props.selectedOption) {
      setSelectedOption(props.selectedOption);
    }
  }, [props.selectedOption]);

  const handleOptionChange = event => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (props.change) {
      props.change(selectedValue)
}
  }


  return (
    <div className={style.MainDropdownWrapper}>
      <span>{props.DropdownTitle}</span>
      <select name=""
        className={style.MainDropdown}
        onChange={handleOptionChange}
        value={selectedOption}
      >
        {props.dataArray.map((attribute) =>
          <option value={attribute.name} key={attribute.name}>
            {attribute.name}
          </option>)
        }
      </select>
    </div>
  )
}

export default MainDropdown;