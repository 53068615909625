import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import style from './AdminHeroes.module.css';
import { Link } from 'react-router-dom';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import Filter from '../../containers/Filter/Filter';
import '../AdminPage/Admin.css'
import { Classes, Elements, Factions, Rarity, Roles } from '../../content/attributes';
import handleRequest from '../../utils/inputCheck';

import GuardianCard from '../../components/GuardianCard/GuardianCard';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner';

import { isAdmin } from '../../utils/userUtils';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminHeroes = () => {
	const [heroes, setHeroes] = useState(null);
	const [selectedClass, setSelectedClass] = useState([]);
	const [selectedElement, setSelectedElement] = useState([]);
	const [selectedFaction, setSelectedFaction] = useState([]);
	const [selectedRarity, setSelectedRarity] = useState([]);
	const [selectedRole, setSelectedRole] = useState([]);
	const [searchText, setSearchText] = useState('');
	const { user } = useUser()

	const getHeroes = () => {
		axios.get(`${API_URL}/guardians`)
			.then(res => setHeroes(res.data.guardians))
	}

	const deleteGuardian = async (id, userId) => {
		toast('deleting guardian');
		handleRequest(
			{},
			user,
			userId,
			`${API_URL}/guardians/${id}`,
			'delete',
			'Hero deleted',
			null,
			null);
		setHeroes(heroes.filter(hero => hero._id !== id));
	};

	useEffect(() => {
		getHeroes()
	}, [])


	const filteredGuardians = heroes
		? heroes.filter((guardian) => {
			const classMatch =
				selectedClass.length === 0 || selectedClass.includes(guardian.class);

			const elementMatch =
				selectedElement.length === 0 || selectedElement.includes(guardian.element);

			const factionMatch =
				selectedFaction.length === 0 || selectedFaction.includes(guardian.faction);

			const rarityMatch =
				selectedRarity.length === 0 || selectedRarity.includes(guardian.rarity);

			const roleMatch =
				selectedRole.length === 0 || selectedRole.includes(guardian.role);

			const searchMatch =
				searchText === '' ||
				guardian.name.toLowerCase().includes(searchText.toLowerCase());

			return (
				classMatch &&
				elementMatch &&
				factionMatch &&
				rarityMatch &&
				roleMatch &&
				searchMatch
			);
		})
		: null;

	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<Filter
					filterArray={[
						{
							name: 'Class',
							items: Classes
						},

						{
							name: 'Element',
							items: Elements
						},

						{
							name: 'Faction',
							items: Factions
						},

						{
							name: 'Rarity',
							items: Rarity
						},

						{
							name: 'Role',
							items: Roles
						}
					]}

					setSearchText={setSearchText}
					selectedClass={selectedClass}
					setSelectedClass={setSelectedClass}
					selectedElement={selectedElement}
					setSelectedElement={setSelectedElement}
					selectedFaction={selectedFaction}
					setSelectedFaction={setSelectedFaction}
					selectedRarity={selectedRarity}
					setSelectedRarity={setSelectedRarity}
					selectedRole={selectedRole}
					setSelectedRole={setSelectedRole}
				/>
				<div className='AdminTopBar'>
					<span>Heroes</span>
					<div>
						<Link to='/add-hero'>
							<PrimaryCTA
								buttonText='Add hero'
								buttonClick={() => null}
							/>
						</Link>
					</div>
				</div>

				<div className={style.GuardianPageContent}>
					{filteredGuardians !== null ? (
						filteredGuardians.length > 0 ? (
							filteredGuardians
								.sort((a, b) => a.name.localeCompare(b.name)) // Sort the guardians alphabetically based on the name
								.map((guardian) => (
									<GuardianCard
										guardianName={guardian.name}
										guardianDescription={guardian.description}
										guardianPrice={guardian.price}
										guardianRole={guardian.role}
										guardianClass={guardian.class}
										guardianImageURL={guardian.imageURL}
										guardianID={guardian._id}
										editable={true}
										delete={() => deleteGuardian(guardian._id, user.id)}
									/>
								))
						) : (
							<span>No results found.</span>
						)
					) : (
						<div className='SpinnerContainer'>
							<Spinner />
						</div>)}
				</div>


			</div>
		</div>
	)
}

export default AdminHeroes;