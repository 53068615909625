import React, { useState } from "react";
import style from './DungeonCard.module.css';
import elipsis from '../../assets/elipsis.svg';
import ToolTip from '../ToolTip/ToolTip';

const DungeonCard = props => {
	const [openToolTip, setOpenToolTip] = useState(false);


	return (
		<div
			style={{
				backgroundColor: `${props.color}`,
				backgroundImage: `url(${props.image})`,
			}}
			className={style.DungeonCardWrapper}>

			<div className={style.DungeonWrapper}>
				<div className={style.DungeonCardContent}>
					<span className={style.DungeonHeading}>{props.dungeonName}</span>
					<span className={style.DungeonText}>{props.description}</span>
				</div>

				{
					props.editable ?
						<div className={style.Elipsis}>
							<div
								className={style.ElipsisWrapper}
								onClick={() => setOpenToolTip(!openToolTip)}
							>
								<img
									src={elipsis}
									alt=""
									className={style.ElipsisIcon} />
							</div>

							{
								openToolTip ?
									<ToolTip
										viewLink={`/admin-dungeon-levels/${props.id}`}
										editLink={`/edit-dungeon/${props.id}`}
										delete={props.delete}
									/>
									: null

							}
						</div>
						: null
				}
			</div>

		</div>
	)
}

export default DungeonCard;