import React from 'react';
import style from './SquadPowerBar.module.css';

const SquadPowerBar = (props) => {
	const powerPercentage = (props.power / 15000) * 100;

	return (
		<div className={style.SquadPowerBar}>
			<div className={style.SquadPower} style={{ width: `${powerPercentage}%` }}>
				<span className={style.SquadPowerNumber}>{props.power}</span>
			</div>
		</div>
	);
};

export default SquadPowerBar;