import React, { useEffect } from "react";
import style from './../Register/Register.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { RedirectToSignIn, SignedOut, useUser, SignIn } from "@clerk/clerk-react";
import axios from "axios";
import PrimaryCTA from "../../../components/Buttons/PrimaryCTA/PrimaryCTA";
import MainInput from "../../../components/MainInput/MainInput";

const Login = () => {
	const { user } = useUser()
	const navigate = useNavigate();

	return (
		<div className={style.LoginContainer}>
			<SignIn />
		</div>
	)
}

export default Login;