import React, { useEffect, useState } from 'react';
import Filter from '../../containers/Filter/Filter';

import axios from 'axios';
import style from './AdminPets.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '@clerk/clerk-react';
import { isAdmin } from '../../utils/userUtils';
import handleRequest from '../../utils/inputCheck';

import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import PetCard from '../../components/PetCard/PetCard';
import { Classes, Rarity } from '../../content/attributes';
import Spinner from '../../components/Spinner/Spinner';


const AdminPets = () => {
	const [pets, setPets] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [selectedClass, setSelectedClass] = useState([]);
	const [selectedRarity, setSelectedRarity] = useState([]);
	const { user } = useUser()

	const API_URL = process.env.REACT_APP_BACKEND_API;

	const getPets = () => {
		axios.get(`${API_URL}/pets`)
			.then(res => setPets(res.data.pets))
			.catch(err => console(err))
	}

	const deletePet = async (id, userId) => {
		toast('deleting pet');
		handleRequest(
			{},
			user,
			userId,
			`${API_URL}/pets/${id}`,
			'delete',
			'pet deleted',
			null,
			null);
		setPets(pets.filter(pet => pet._id !== id));
	};


	useEffect(() => {
		getPets()
	}, [])

	const filteredPets = pets
		? pets.filter((pet) => {
			const classMatch =
				selectedClass.length === 0 || selectedClass.includes(pet.class);

			const rarityMatch =
				selectedRarity.length === 0 || selectedRarity.includes(pet.rarity);

			const searchMatch =
				searchText === '' ||
				pet.name.toLowerCase().includes(searchText.toLowerCase());

			return (
				classMatch &&
				rarityMatch &&
				searchMatch
			);
		})
		: null;


	return (
		<div>
			<div className='AdminPage'>
				<AdminSideBar />

				<div className='AdminContentWrapper'>
					<Filter
						filterArray={[
							{
								name: 'Class',
								items: Classes
							},

							{
								name: 'Rarity',
								items: Rarity
							},
						]}

						setSearchText={setSearchText}
						selectedClass={selectedClass}
						setSelectedClass={setSelectedClass}
						selectedRarity={selectedRarity}
						setSelectedRarity={setSelectedRarity}
					/>

					<div className='AdminTopBar'>
						<span>Pets</span>

						<div>
							<Link to='/add-pet'>
								<PrimaryCTA buttonText='Add pet'
									buttonClick={() => null}
								/>
							</Link>
						</div>
					</div>

					<div className={style.PetsPageContent}>
						{filteredPets !== null ? (
							filteredPets.length > 0 ? (
								filteredPets
									.sort((a, b) => a.name.localeCompare(b.name))
									.map((pet) => (
										<PetCard
											petName={pet.name}
											petDescription={pet.description}
											petPrice={pet.price}
											petRole={pet.role}
											petClass={pet.class}
											petRarity={pet.rarity}
											petImageURL={pet.imageURL}
											petID={pet._id}
											delete={() => deletePet(pet._id, user.id)}
											editable={true}
										/>
									))
							) : (
								<span>No results found.</span>
							)
						) : (
							<div className='SpinnerContainer'>
								<Spinner />
							</div>
						)}
					</div>


				</div>
			</div>
		</div>
	)
}

export default AdminPets