import React from 'react';
import style from './SecondaryCTA.module.css';

const SecondaryCTA = props => {
	return (
		<button
			className={style.SecondaryCTA}
			onClick={props.click}>
			{props.buttonText}
		</button>
	)
}

export default SecondaryCTA;