import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useUser } from '@clerk/clerk-react';
import { toast } from 'react-toastify';

import style from '../AdminAddDungeons/AdminAddDungeon.module.css'
import { Link } from 'react-router-dom';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import MainInput from '../../components/MainInput/MainInput';
import WikiText from '../../components/WikiText/WikiText';
import MainDropdown from '../../components/MainDropdown/MainDropdown';
import DungeonLevelCard from '../../components/DungeonLevelCard/DungeonLevelCard';

import { Elements } from '../../content/attributes'
import { Tiers } from '../../content/dungeonAttribute';
import handleRequest from '../../utils/inputCheck';


const AdminDungeonLevels = () => {
	const { dungeonID } = useParams()
	const API_URL = process.env.REACT_APP_BACKEND_API;
	const { user } = useUser()

	const [dungeonData, setDungeonData] = useState(
		{
			name: '',
			imageURL: '',
			description: '',
			tier: '',
			element: '',
			DungeonLevels: []
		}
	)

	const getDungeonInfo = () => {
		axios.get(`${API_URL}/dungeons/${dungeonID}`)
			.then(res => setDungeonData(res.data))
			// .then(res => setHeroData(hero))
			.catch(err => console.log(err))
	}

	// '/:id/levels/:levelId'
	const deleteDungeonLevel = async (id, userId, levelId) => {
		toast('deleting level');
		handleRequest(
			{},
			user,
			userId,
			`${API_URL}/dungeons/${dungeonID}/levels/${levelId}`,
			'delete',
			'dungeon level deleted',
			null,
			null
		);

		// Remove the deleted dungeon level from the state
		setDungeonData((prevData) => ({
			...prevData,
			DungeonLevels: prevData.DungeonLevels.filter(
				(level) => level._id !== levelId
			),
		}));
	};

	useEffect(() => {
		getDungeonInfo()
	}, [])

	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<div className={style.AddDungeonContent}>
					<div>
						<div className='AdminTopBar'>
							<span>Dungeon Levels</span>
							<div>
								<Link to={`/add-dungeon-level/${dungeonID}`}>
									<PrimaryCTA 
									buttonText='+ Add level' 
									buttonClick = {() => null}
									/>
								</Link>
							</div>
						</div>

						<div className={style.DungeonsWrapper}>
							{dungeonData.DungeonLevels.length > 0 ? (
								dungeonData.DungeonLevels.map((level) => (

									<DungeonLevelCard
										level={level.level}
										imageURL={level.imageURL}
										power={level.squadPower}
										dungeonID={dungeonID}
										dungeonLevelID={level._id}
										editable={true}
										delete={() => deleteDungeonLevel(dungeonData._id, user.id, level._id)}
									/>
								))
							) : (
								<p>Loading...</p>
							)}
						</div>

					</div>

				</div>
			</div>
		</div>
	)
}

export default AdminDungeonLevels;