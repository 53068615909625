import React, { useState } from 'react';
import style from './FilterItem.module.css';

const FilterItem = (props) => {
	const [selected, setSelected] = useState(false);

	const handleItemClick = () => {
		setSelected(!selected);
		props.onItemClick(props.name); // Pass the item name to the callback prop
	};

	// Define the CSS class based on the selected state
	const itemClass = selected ? `${style.FilterItemWrapper} ${style.Selected}` : style.FilterItemWrapper;

	return (
		<div className={itemClass} onClick={handleItemClick}>
			<img className={style.FilterIcon} src={props.icon} alt='filter icon' />
			<span className={style.FilterName}>{props.name}</span>
		</div>
	);
};

export default FilterItem;