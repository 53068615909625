import React, { useContext, useState, useEffect } from "react";
import axios from 'axios'
import style from './Navbar.module.css';
import { Link } from "react-router-dom";
import { RedirectToSignIn, SignedOut, SignIn, useUser, UserButton } from "@clerk/clerk-react";
import SecondaryCTA from '../../components/Buttons/SecondaryCTA/SecondaryCTA';
import Logo from '../../assets/gog-logo.svg'

const Navbar = () => {
	const { user } = useUser()
	const [dbUser, setDbUser] = useState(null)
	const API_URL = process.env.REACT_APP_BACKEND_API;

	const getDbUser = () => {
		axios.get(`${API_URL}/users/${user.id}`).then((res) => setDbUser(res.data[0]));
	}

	useEffect(() => {
		if (user) {
			getDbUser()
		}
	}, [user])

	return (
		<nav className={style.NavBar}>
			<div className={style.NavWrapper}>
				<Link to='home'>
					<img src={Logo} alt="" className={style.gogLogo}/>
				</Link>

				<div className={style.NavContent}>
					<Link to='/home'>
						<span className={style.NavBarItem}>Home</span>
					</Link>

					<Link to='/guardians'>
						<span className={style.NavBarItem}>Heroes</span>
					</Link>

					<Link to='/pets'>
						<span className={style.NavBarItem}>Pets</span>
					</Link>

					<Link to='/dungeons'>
						<span className={style.NavBarItem}>Dungeons</span>
					</Link>

					<Link to='/team-composition'>
						<span className={style.NavBarItem}>Team Synergies</span>
					</Link>

					{
						(dbUser && dbUser.userRole === 'Admin')
							?
							<Link to='/admin'>
								<span className={style.NavBarItem}>Admin</span>
							</Link>
							: null
					}
				</div>

				{
					user ?
						<div className={style.NavGreeting}>
							<span className={style.NavUserName}>Hi, {user.username}!</span>
							<UserButton />
						</div>
						:
						<Link to='/login'>
							<SecondaryCTA
								buttonText='Login'
							/>
						</Link>
				}
				{/* <button onClick={() => loginWithRedirect()}>Log In</button> */}
			</div>
		</nav>
	)
}

export default Navbar;