import React, { useState, useEffect } from 'react';
import style from './Guardians.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import cyrusImage from '../../assets/cyrus.png'

import GuardianCard from '../../components/GuardianCard/GuardianCard';
import Search from '../../components/Search/Search';
import Filter from '../../containers/Filter/Filter';
import PaginationButtons from '../../components/PaginationButtons/PaginationButtons';
import Spinner from '../../components/Spinner/Spinner';


import { Classes, Elements, Factions, Rarity, Roles } from '../../content/attributes';

const Guardians = () => {
	const [guardians, setGuardians] = useState(null);
	const [selectedClass, setSelectedClass] = useState([]);
	const [selectedElement, setSelectedElement] = useState([]);
	const [selectedFaction, setSelectedFaction] = useState([]);
	const [selectedRarity, setSelectedRarity] = useState([]);
	const [selectedRole, setSelectedRole] = useState([]);
	const [searchText, setSearchText] = useState('');


	const API_URL = process.env.REACT_APP_BACKEND_API;
	const getGuardians = () => {
		try {
			axios.get(`${API_URL}/guardians`)
				.then(res => {
					setGuardians(res.data.guardians)
				}
				)
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		getGuardians()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const filteredGuardians = guardians
		? guardians.filter((guardian) => {
			const classMatch =
				selectedClass.length === 0 || selectedClass.includes(guardian.class);

			const elementMatch =
				selectedElement.length === 0 || selectedElement.includes(guardian.element);

			const factionMatch =
				selectedFaction.length === 0 || selectedFaction.includes(guardian.faction);

			const rarityMatch =
				selectedRarity.length === 0 || selectedRarity.includes(guardian.rarity);

			const roleMatch =
				selectedRole.length === 0 || selectedRole.includes(guardian.role);

			const searchMatch =
				searchText === '' ||
				guardian.name.toLowerCase().includes(searchText.toLowerCase());

			return (
				classMatch &&
				elementMatch &&
				factionMatch &&
				rarityMatch &&
				roleMatch &&
				searchMatch
			);
		})
		: null;


	return (
		<div className={style.GuardiansPage}>
			<div className={style.GuardianContentWrapper}>
				<h2 className="PageHeading">Heroes</h2>

				<Filter
					filterArray={[
						{
							name: 'Class',
							items: Classes,
						},
						{
							name: 'Element',
							items: Elements,
						},
						{
							name: 'Faction',
							items: Factions,
						},
						{
							name: 'Rarity',
							items: Rarity,
						},
						{
							name: 'Role',
							items: Roles,
						},
					]}
					setSearchText={setSearchText}
					selectedClass={selectedClass}
					setSelectedClass={setSelectedClass}
					selectedElement={selectedElement}
					setSelectedElement={setSelectedElement}
					selectedFaction={selectedFaction}
					setSelectedFaction={setSelectedFaction}
					selectedRarity={selectedRarity}
					setSelectedRarity={setSelectedRarity}
					selectedRole={selectedRole}
					setSelectedRole={setSelectedRole}
				/>

				<div className={style.GuardianPageContent}>
					{filteredGuardians !== null ? (
						filteredGuardians.length > 0 ? (
							filteredGuardians
								.sort((a, b) => a.name.localeCompare(b.name)) // Sort the guardians alphabetically based on the name
								.map((guardian) => (
									<Link to={`/guardian/${guardian._id}`} key={guardian._id}>
										<GuardianCard
											guardianName={guardian.name}
											guardianDescription={guardian.description}
											guardianPrice={guardian.price}
											guardianRole={guardian.role}
											guardianClass={guardian.class}
											guardianImageURL={guardian.imageURL}
										/>
									</Link>
								))
						) : (
							<span>No results found.</span>
						)
					) : (
						<div className='SpinnerContainer'>
							<Spinner />
						</div>
					)}
				</div>
			</div>

		</div>
	)
}

export default Guardians;