import React, { useState } from 'react';
import style from '../GuardianWiki/GuardianWiki.module.css';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';

import classIcon from '../../assets/class_icon.svg';
import elementIcon from '../../assets/element_icon.svg';
import factionIcon from '../../assets/faction_icon.svg';
import roleIcon from '../../assets/role_icon.svg';
import passiveIcon from '../../assets/passive_icon.svg';
import activeIcon from '../../assets/active_icon.svg';
import liaImage from '../../assets/lia.png'
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const AdminHeroPreviewPage = props => {
	// const [guardian, setGuardian] = useState({})


	// console.log(guardian.name)
	return (
		<div className='AdminPage'>
			<div className={style.GuardianContainer}>
				<div className={style.GuardianInfoWrapper}>
					<div className={style.GuardianInfo}>
						<div className={style.GuardianNameWrapper}>
							<h1 className={style.GuardianName}>{props.heroData.name}</h1>
							<h4 className={style.GuardianDescription}>{props.heroData.description}</h4>
						</div>

						{/* <div className={style.GuardianPriceWrapper}>
							<span className={style.PriceHeading}>Current Price</span>
							<div className={style.GuardianPrice}>
								<span className={style.ETHPrice}>{guardian.price}</span>
								<span className={style.USDPrice}>$170.68</span>
							</div>
						</div> */}

						<div className={style.AttributesWrapper}>
							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={roleIcon}
										className={style.AttributeIcon}
										alt="" />
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Role</span>
										<span className={style.AttributeText}>{props.heroData.role}</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={classIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Class</span>
										<span className={style.AttributeText}>{props.heroData.class}</span>
									</div>
								</div>
							</div>

							<div className={style.AttributesContainer}>
								<div className={style.Attribute}>
									<img
										src={factionIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Faction</span>
										<span className={style.AttributeText}>{props.heroData.faction}</span>
									</div>
								</div>

								<div className={style.Attribute}>
									<img
										src={elementIcon}
										alt=""
										className={style.AttributeIcon}
									/>
									<div className={style.AttributesTextContainer}>
										<span className={style.AttributeHeading}>Element</span>
										<span className={style.AttributeText}>{props.heroData.element}</span>
									</div>
								</div>

							</div>
						</div>

						<div className={style.ExtraAtrributesContainer}>
							<div className={style.ExtraAtrributeTitleContainer}>
								<span>Rarity</span>
								<span>Edition</span>
							</div>

							<div className={style.ExtraAtrributes}>
								<div className={style.ExtraAtrributeTextContainer}>
									<span>{props.heroData.rarity}</span>
									<span>{props.heroData.edition}</span>
								</div>
							</div>
						</div>

						<div className={style.OverviewContainer}>
							<h2 className={style.OverviewHeading}>Overview</h2>
							<p className={style.OverviewText}>{props.heroData.overview}</p>
						</div>

					</div>

					<div className={style.GuardianImageContainer}>
						<img
							// src={guardian.imageURL}
							src={props.heroData.imageURL}
							alt=""
							className={style.GuardianImage}
						/>
					</div>
				</div>

				<div className={style.AbilitiesWrapper}>
					<div className={style.AbilityContainer}>
						<img src={passiveIcon} alt="" className={style.AbilityIcon} />
						<div className={style.AbilityContent}>
							<span className={style.AbilityHeading}>Passive ability</span>
							<span className={style.AbilityText}>{props.heroData.passiveAbility}</span>
							<span className={style.AbilityDescription}>
								{props.heroData.activeAbilityDescription}
							</span>
						</div>
					</div>

					<div className={style.AbilityContainer}>
						<img src={activeIcon} alt="" className={style.AbilityIcon} />
						<div className={style.AbilityContent}>
							<span className={style.AbilityHeading}>
								Ultimate ability
							</span>
							<span className={style.AbilityText}>{props.heroData.activeAbility}</span>
							<span className={style.AbilityDescription}>
								{props.heroData.passiveAbilityDescription}
							</span>
						</div>
					</div>
				</div>

				<div className={style.WikiTextContainer}>
					<ReactMarkdown>
						{props.heroData.wikiText}
					</ReactMarkdown>
					<div className={style.WikiTextWrapper}>
					</div>
				</div>
			</div>
		</div>
	)
}
export default AdminHeroPreviewPage;