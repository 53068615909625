import React from 'react';
import style from '../PetWiki/PetWiki.module.css';

import classIcon from '../../assets/class_icon.svg';
import elementIcon from '../../assets/element_icon.svg';
import factionIcon from '../../assets/faction_icon.svg';
import roleIcon from '../../assets/role_icon.svg';
import passiveIcon from '../../assets/passive_icon.svg';
import activeIcon from '../../assets/active_icon.svg';
import namurImage from '../../assets/namur-image.png'

import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const AdminPetPreviewPage = props => {
	return (
		<div className={style.PetContainer}>
			<div className={style.PetInfoWrapper}>
				<div className={style.PetInfo}>
					<div className={style.PetNameWrapper}>
						<h1 className={style.PetName}>{props.petData.name}</h1>
						<h4 className={style.PetDescription}>{props.petData.description}</h4>
					</div>

					{/* <div className={style.PetPriceWrapper}>
						<span className={style.PriceHeading}>Current Price</span>
						<div className={style.PetPrice}>
							<span className={style.ETHPrice}>0.1069</span>
							<span className={style.USDPrice}>$170.68</span>
						</div>
					</div> */}

					<div className={style.AttributesWrapper}>
						<div className={style.AttributesContainer}>
							<div className={style.Attribute}>
								<img
									src={roleIcon}
									alt=""
									className={style.AttributeIcon}
								/>
								<div className={style.AttributesTextContainer}>
									<span className={style.AttributeHeading}>
										Role</span>
									<span className={style.AttributeText}>{props.petData.role}</span>
								</div>
							</div>

							<div className={style.Attribute}>
								<img
									src={classIcon}
									alt=""
									className={style.AttributeIcon}
								/>
								<div className={style.AttributesTextContainer}>
									<span className={style.AttributeHeading}>Class</span>
									<span className={style.AttributeText}>{props.petData.class}</span>
								</div>
							</div>
						</div>

						<div className={style.AttributesContainer}>
							<div className={style.Attribute}>
								<img
									src={factionIcon}
									alt=""
									className={style.AttributeIcon}
								/>
								<div className={style.AttributesTextContainer}>
									<span className={style.AttributeHeading}>Faction</span>
									<span className={style.AttributeText}>
										{props.petData.faction}
									</span>
								</div>
							</div>

							<div className={style.Attribute}>
								<img
									src={elementIcon}
									alt=""
									className={style.AttributeIcon}
								/>
								<div className={style.AttributesTextContainer}>
									<span className={style.AttributeHeading}>Element</span>
									<span className={style.AttributeText}>
										{props.petData.element}
									</span>
								</div>
							</div>

						</div>
					</div>

					<div className={style.ExtraAtrributesContainer}>
						<div className={style.ExtraAtrributeTitleContainer}>
							<span>Rarity</span>
							<span>Edition</span>
						</div>

						<div className={style.ExtraAtrributes}>
							<div className={style.ExtraAtrributeTextContainer}>
								<span>{props.petData.rarity}</span>
								<span>{props.petData.edition}</span>
							</div>
						</div>
					</div>

					<div className={style.OverviewContainer}>
						<h2 className={style.OverviewHeading}>Overview</h2>
						<p className={style.OverviewText}>{props.petData.overview}</p>
					</div>

				</div>

				<div className={style.PetImageContainer}>
					<img
						src={props.petData.imageURL}
						alt=""
						className={style.PetImage}
					/>
				</div>
			</div>

			{/* <div className={style.AbilitiesWrapper}>
				<div className={style.AbilityContainer}>
					<img src={passiveIcon} alt="" className={style.AbilityIcon} />
					<div className={style.AbilityContent}>
						<span className={style.AbilityHeading}>Passive ability</span>
						<span className={style.AbilityText}>{props.petData.activeAbility}</span>
						<span className={style.AbilityDescription}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident et eveniet iusto officiis ex aut! Alias non voluptatibus quo est quibusdam similique aspernatur magni pariatur voluptatum commodi vel, earum laboriosam!</span>
					</div>
				</div>

				<div className={style.AbilityContainer}>
					<img src={activeIcon} alt="" className={style.AbilityIcon} />
					<div className={style.AbilityContent}>
						<span className={style.AbilityHeading}>Active ability</span>
						<span className={style.AbilityText}>{props.petData.passiveAbility}</span>
						<span className={style.AbilityDescription}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident et eveniet iusto officiis ex aut! Alias non voluptatibus quo est quibusdam similique aspernatur magni pariatur voluptatum commodi vel, earum laboriosam!</span>
					</div>
				</div>
			</div> */}

			<div className={style.WikiTextContainer}>
				<ReactMarkdown>
					{props.petData.wikiText}
				</ReactMarkdown>

				<div className={style.WikiTextWrapper}></div>
			</div>
		</div>
	);
}

export default AdminPetPreviewPage;