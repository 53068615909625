import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import handleRequest from '../../utils/inputCheck';

import style from '../AdminAddDungeonLevel/AdminAddDungeonLevel.module.css'
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import MainInput from '../../components/MainInput/MainInput';
import WikiText from '../../components/WikiText/WikiText';
import AdminDungeonPreviewPage from '../AdminDungeonPreviewPage/AdminDungeonPreviewPage';
import FileUpload from '../../components/FileUpload/FileUpload';

// router.get('/:id/levels/:levelId'


const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminEditDungeonLevel = () => {
	const navigate = useNavigate();
	const { user } = useUser()
	const { dungeonID, dungeonLevelID } = useParams();
	const [showPreview, setShowPreview] = useState(false);

	const [dungeonLevelData, setDungeonLevelData] = useState(
		{
			level: '',
			imageURL: '',
			squadPower: 0,
			wikiText: ''
		}
	)

	const handleSubmit = async (e) => {
		toast('updating dungeon level')
		handleRequest(
			dungeonLevelData,
			user,
			user.id,
			`${API_URL}/dungeons/${dungeonID}/levels/${dungeonLevelID}`,
			'patch',
			`Updated level`,
			navigate,
			`/admin-dungeon-levels/${dungeonID}`
		)
	};

	const getDungeonLevelInfo = () => {
		axios.get(`${API_URL}/dungeons/${dungeonID}/levels/${dungeonLevelID}`)
			.then(res => setDungeonLevelData(res.data))
			// .then(res => setHeroData(hero))
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getDungeonLevelInfo()
	}, [])

	const handleLevelChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, level: event.target.value });
	}

	const handleImageLinkChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, imageURL: event.target.value });
	}

	const handleSquadPowerChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, squadPower: event.target.value });
	}

	const handleWikiTextChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, wikiText: event.target.value });
	}

	const handleSetUploadLink = (imageLink) => {
		setDungeonLevelData({ ...dungeonLevelData, imageURL: imageLink });
	};


	return (
		<div>
			<div className='AdminPage'>
				<AdminSideBar />

				<div className='AdminContentWrapper'>
					<div className='AdminTopBar'>
						<h2>Edit Dungeon Level</h2>
						<div className='AdminButtonWrapper'>
							<span onClick={
								() => setShowPreview(!showPreview)}>
								Preview
							</span>

							<PrimaryCTA
								buttonText='Update Dungeon Level'
								buttonClick={handleSubmit}
							/>
						</div>
					</div>

					{
						showPreview ?
							<AdminDungeonPreviewPage
								dungeonData={dungeonLevelData}
							/>
							:
							<div className={style.InputWrapper}>
								<div className={style.MainInputContainer}>
									<div className={style.MainInputWrapper}>
										<MainInput
											TitleText='Dungeon level name'
											placeholder='Dungeon level name'
											change={handleLevelChange}
											value={dungeonLevelData.level}
										/>

										<MainInput
											TitleText='Dungeon level image link'
											placeholder='Dungeon level image link'
											change={handleImageLinkChange}
											value={dungeonLevelData.imageURL}
										/>

										<FileUpload
											onUploadComplete={handleSetUploadLink}
										/>
									</div>

									<div className={style.MainInputWrapper}>
										<MainInput
											TitleText='Dungeon recommended power'
											placeholder='Dungeon recommended power'
											change={handleSquadPowerChange}
											value={dungeonLevelData.squadPower}
										/>
									</div>

								</div>

								<WikiText
									TitleText='Dungeon Wiki'
									placeholder='Dungeon Wiki'
									change={handleWikiTextChange}
									value={dungeonLevelData.wikiText}
								/>
							</div>
					}
				</div>
			</div>
		</div>
	)
}

export default AdminEditDungeonLevel;