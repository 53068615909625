import React, { useState } from 'react';
import style from './UserCard.module.css';
import elipsis from '../../assets/elipsis.svg';
import ToolTip from '../ToolTip/ToolTip';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useUser } from '@clerk/clerk-react';
import { isAdmin } from '../../utils/userUtils';

const API_URL = process.env.REACT_APP_BACKEND_API;


const UserCard = (props) => {
	const [openToolTip, setOpenToolTip] = useState(false);
	const [selectedRole, setSelectedRole] = useState(props.userRole);
	const { user } = useUser()

	const handleRoleChange = async (event) => {
		const newRole = event.target.value;
		setSelectedRole(newRole);

		if (user && user.id) {
			try {
				// Check if the user is an admin
				const userIsAdmin = await isAdmin(user.id);

				// If the user is not an admin, show an error
				if (!userIsAdmin) {
					toast.error('You are not authorized to perform this action.');
					return;
				}

				// Make the GET request to check if the user whose role is being changed exists
				const userExistsResponse = await axios.get(`${API_URL}/users/${props.userId}`);

				if (userExistsResponse.status === 200) {
					// User exists, proceed to update the user's role
					const response = await axios.patch(`${API_URL}/users/${props.userId}`, { userRole: newRole, userId: user.id });

					if (response.status === 200) {
						toast.success(`${props.username} is now a ${newRole}`);
					} else {
						throw new Error('Request failed');
					}
				} else if (userExistsResponse.status === 404) {
					toast.error('User not found');
				} else {
					throw new Error('Unexpected API response');
				}
			} catch (error) {
				toast.error('Failed to update the user role');
				console.error(error);
			}
		}
	};




	return (
		<div className={style.UserCardWrapper}>
			<div className={style.UserInfoWrapper}>
				<img className={style.UserImage} src={props.userImage} alt="" />
				<span className={style.UserName}>{props.username}</span>
				<div>
					<select
						value={selectedRole}
						onChange={handleRoleChange}
						className={style.Dropdown}
					>
						<option value="Member">Member</option>
						<option value="Admin">Admin</option>
					</select>
				</div>			</div>

			<div className={style.Elipsis}>
				<div
					className={style.ElipsisWrapper}
					onClick={() => setOpenToolTip(!openToolTip)}
				>
					<img src={elipsis} alt="" className={style.ElipsisIcon} />
				</div>

				{openToolTip ? (
					<ToolTip
						// viewLink={`/guardian/${props.guardianID}`}
						// editLink={`/edit-hero/${props.guardianID}`}
					// delete={deleteGuardian}
					/>
				) : null}
			</div>
		</div>
	);
};

export default UserCard;
