import React, { useState, useEffect } from 'react';
import style from './TeamComposition.module.css';
import axios from 'axios';

import CompositionCard from "../../components/CompositionCard/CompositionCard";
import PrimaryCTA from "../../components/Buttons/PrimaryCTA/PrimaryCTA";
import Filter from '../../containers/Filter/Filter';
import TeamCompositionOverlay from "../../containers/TeamCompositionOverlay/TeamCompositionOverlay";
import { toast } from 'react-toastify';

import { Classes, Elements, Factions, Rarity, Roles } from '../../content/attributes';
import NAIcon from '../../assets/icons/naIcon.svg'
import Spinner from '../../components/Spinner/Spinner';



const TeamComposition = () => {
	const [IsOverlayOpen, setIsOverlayOpen] = useState(false);
	const [guardians, setGuardians] = useState(null);
	const [selectedClass, setSelectedClass] = useState([]);
	const [selectedElement, setSelectedElement] = useState([]);
	const [selectedFaction, setSelectedFaction] = useState([]);
	const [selectedRarity, setSelectedRarity] = useState([]);
	const [selectedRole, setSelectedRole] = useState([]);
	const [searchText, setSearchText] = useState('');

	const [squad, setSquad] = useState([]);

	const API_URL = process.env.REACT_APP_BACKEND_API;

	const getGuardians = () => {
		axios.get(`${API_URL}/guardians`)
			.then(res => setGuardians(res.data.guardians))
	}

	useEffect(() => {
		getGuardians()
	}, [])

	const showOverlay = () => {
		setIsOverlayOpen(!IsOverlayOpen)
	}

	const addGuardianToSquad = (guardian) => {
		if (squad.length >= 4) {
			toast.error('Squad is already full')
			return
		}

		setSquad([...squad, guardian])
		toast.success(`Added ${guardian.name} to your squad`)
	}

	const filteredGuardians = guardians
		? guardians.filter((guardian) => {
			const classMatch =
				selectedClass.length === 0 || selectedClass.includes(guardian.class);

			const elementMatch =
				selectedElement.length === 0 || selectedElement.includes(guardian.element);

			const factionMatch =
				selectedFaction.length === 0 || selectedFaction.includes(guardian.faction);

			const rarityMatch =
				selectedRarity.length === 0 || selectedRarity.includes(guardian.rarity);

			const roleMatch =
				selectedRole.length === 0 || selectedRole.includes(guardian.role);

			const searchMatch =
				searchText === '' ||
				guardian.name.toLowerCase().includes(searchText.toLowerCase());

			return (
				classMatch &&
				elementMatch &&
				factionMatch &&
				rarityMatch &&
				roleMatch &&
				searchMatch
			);
		})
		: null;


	return (
		<div>
			<h2 className='PageHeading'>Team Synergies</h2>
			<Filter
				filterArray={[
					{
						name: 'Class',
						items: Classes
					},

					{
						name: 'Element',
						items: Elements
					},

					{
						name: 'Faction',
						items: Factions
					},

					{
						name: 'Rarity',
						items: Rarity
					},

					{
						name: 'Role',
						items: Roles
					}
				]}

				setSearchText={setSearchText}
				selectedClass={selectedClass}
				setSelectedClass={setSelectedClass}
				selectedElement={selectedElement}
				setSelectedElement={setSelectedElement}
				selectedFaction={selectedFaction}
				setSelectedFaction={setSelectedFaction}
				selectedRarity={selectedRarity}
				setSelectedRarity={setSelectedRarity}
				selectedRole={selectedRole}
				setSelectedRole={setSelectedRole}
			/>

			<div className={style.SquadCardContainer}>
				{filteredGuardians !== null ? (
					filteredGuardians.length > 0 ? (
						filteredGuardians
							.sort((a, b) => a.name.localeCompare(b.name)) // Sort the guardians alphabetically based on the name
							.map((guardian) => (
								<CompositionCard
									guardianName={guardian.name}
									guardianRole={guardian.role}
									guardianImage={guardian.imageURL}
									guardianClass={guardian.class}
									guardianFaction={guardian.faction}
									guardianElement={guardian.element}
									click={() => addGuardianToSquad(guardian)}
								/>
							))
					) : (
						<span>No results found.</span>
					)
				) : (
					<div className='SpinnerContainer'>
						<Spinner />
					</div>)}
			</div>


			<div className={style.SquadNumberWrapper}>
				<div className={style.SquadNumber}>
					<span className={style.SquadNumberText}>Squad Number: {squad.length} of 4</span>

					<div className={style.SquadButtonWrapper}>
						<PrimaryCTA
							buttonText='View Squad'
							buttonClick={showOverlay}
						/>
					</div>
				</div>
			</div>

			{
				IsOverlayOpen ?
					<div
						className={style.TeamCompositionOverlayBackground}
						onClick={showOverlay}
					>
						<TeamCompositionOverlay
							guardians={squad}
							updateSelectedSquad={setSquad}
							overlayState = {showOverlay}
						/>
					</div> : null
			}
		</div>
	)
}

export default TeamComposition;