import axios from 'axios';
const API_URL = process.env.REACT_APP_BACKEND_API;

// Function to check if a user is an admin
export async function isAdmin(userId) {
	try {
		// fetch user details
		const response = await axios.get(`${API_URL}/users/${userId}`);
		const user = response.data;

		if (user && user[0].userRole === 'Admin') {
			return true; // User is an admin
		} else {
			return false; // User is not an admin
		}
	} catch (error) {
		console.error('Error checking admin status:', error);
		return false; // Handle errors gracefully
	}
}

// Usage example:
// const userId = 'user_2JBPp2B'; // Replace with the actual user ID
// (async () => await isAdmin(userId))();  