import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

import style from './AdminAddDungeonLevel.module.css';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import MainInput from '../../components/MainInput/MainInput';
import WikiText from '../../components/WikiText/WikiText';
import AdminDungeonPreviewPage from '../AdminDungeonPreviewPage/AdminDungeonPreviewPage';
import FileUpload from '../../components/FileUpload/FileUpload';
import handleRequest from '../../utils/inputCheck';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminAddDungeonLevel = () => {
	const navigate = useNavigate()
	const { user } = useUser()
	const { dungeonID } = useParams()
	const [showPreview, setShowPreview] = useState(false)

	const [dungeonLevelData, setDungeonLevelData] = useState(
		{
			level: '',
			imageURL: '',
			squadPower: 0,
			wikiText: ''
		}
	)

	const handleSubmit = (e) => {
		// e.preventDefault();
		const isDataMissing = Object.values(dungeonLevelData).some(
			(value) => value === '' || value === undefined
		);

		if (isDataMissing) {
			toast.error('Some fields are missing');
			return;
		}

		axios
			.post(`${API_URL}/dungeons/${dungeonID}/DungeonLevels`, dungeonLevelData)
			.then(res => {
				if (res.status === 200) {
					toast.success(`Added Dungeon Level`);
					navigate(`/edit-dungeon/${dungeonID}`)
				} else {
					toast.error('Request failed');
				}
			})
			.catch(err => {
				console.log(err);
				toast.error('An error occurred');
			});
	};

	const handlePost = async (e) => {
		toast('adding dungeon level');
		handleRequest(
			dungeonLevelData,
			user,
			user.id,
			`${API_URL}/dungeons/${dungeonID}/DungeonLevels`,
			'post',
			'duneon level added',
			navigate,
			`/admin-dungeon-levels/${dungeonID}`
		);
		console.log(user.id)
	};

	const handleLevelChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, level: event.target.value });
	}

	const handleImageLinkChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, imageURL: event.target.value });
	}

	const handleSquadPowerChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, squadPower: event.target.value });
	}

	const handleWikiTextChange = event => {
		setDungeonLevelData({ ...dungeonLevelData, wikiText: event.target.value });
	}

	const handleSetUploadLink = (imageLink) => {
		setDungeonLevelData({ ...dungeonLevelData, imageURL: imageLink });
	};

	console.log(dungeonID)

	return (
		<div>
			<div className='AdminPage'>
				<AdminSideBar />

				<div className='AdminContentWrapper'>
					<div className='AdminTopBar'>
						<h2>Add Dungeon Level</h2>
						<div className='AdminButtonWrapper'>
							<span onClick={
								() => setShowPreview(!showPreview)}>
								Preview
							</span>

							<PrimaryCTA
								buttonText='Save Dungeon Level'
								buttonClick={handlePost}
							/>
						</div>
					</div>

					{
						showPreview ?
							<AdminDungeonPreviewPage
								dungeonData={dungeonLevelData}
							/>
							:
							<div className={style.InputWrapper}>
								<div className={style.MainInputContainer}>
									<div className={style.MainInputWrapper}>
										<MainInput
											TitleText='Dungeon level name'
											placeholder='Dungeon level name'
											change={handleLevelChange}
											value={dungeonLevelData.level}
										/>

										<MainInput
											TitleText='Dungeon level image link'
											placeholder='Dungeon level image link'
											change={handleImageLinkChange}
											value={dungeonLevelData.imageURL}
										/>

										<FileUpload
											onUploadComplete={handleSetUploadLink}
										/>

									</div>

									<div className={style.MainInputWrapper}>
										<MainInput
											TitleText='Dungeon recommended power'
											placeholder='Dungeon recommended power'
											change={handleSquadPowerChange}
											value={dungeonLevelData.squadPower}
										/>
									</div>
								</div>

								<WikiText
									TitleText='Dungeon Wiki'
									placeholder='Dungeon Wiki'
									change={handleWikiTextChange}
									value={dungeonLevelData.wikiText}
								/>
							</div>
					}
				</div>
			</div>
		</div>
	)
}

export default AdminAddDungeonLevel;