import React, { useState } from 'react';
import style from './Filter.module.css';
import Search from '../../components/Search/Search';
import FilterCategory from '../../components/FilterCategory/FilterCategory';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';

import dropdownIcon from '../../assets/dropdown.svg';

const Filter = props => {
	const [ModalOpen, setModalOpen] = useState(false);

	const handleSearchChange = (event) => {
		props.setSearchText(event.target.value);
	};

	const handleItemSelect = (category, itemName) => {
		switch (category) {
			case 'Class':
				if (props.selectedClass.includes(itemName)) {
					props.setSelectedClass(prevSelected => prevSelected.filter(item => item !== itemName));
				} else {
					props.setSelectedClass(prevSelected => [...prevSelected, itemName]);
				}
				break;
			case 'Element':
				if (props.selectedElement.includes(itemName)) {
					props.setSelectedElement(prevSelected => prevSelected.filter(item => item !== itemName));
				} else {
					props.setSelectedElement(prevSelected => [...prevSelected, itemName]);
				}
				break;
			case 'Faction':
				if (props.selectedFaction.includes(itemName)) {
					props.setSelectedFaction(prevSelected => prevSelected.filter(item => item !== itemName));
				} else {
					props.setSelectedFaction(prevSelected => [...prevSelected, itemName]);
				}
				break;
			case 'Rarity':
				if (props.selectedRarity.includes(itemName)) {
					props.setSelectedRarity(prevSelected => prevSelected.filter(item => item !== itemName));
				} else {
					props.setSelectedRarity(prevSelected => [...prevSelected, itemName]);
				}
				break;
			case 'Role':
				if (props.selectedRole.includes(itemName)) {
					props.setSelectedRole(prevSelected => prevSelected.filter(item => item !== itemName));
				} else {
					props.setSelectedRole(prevSelected => [...prevSelected, itemName]);
				}
				break;
			default:
				break;
		}
	};


	return (
		<div className={style.Filter}>
			<div className={style.FilterSearchContainer}>
				<div className={style.FilterSearchWrapper}>
					<span className={style.FilterSearchText}>Name:</span>

					<div className={style.Search}>
						<div className={style.SearchWrapper}>
							<input
								type="text"
								className={style.SearchInput}
								placeholder='Type keywords to search...'
								onChange={handleSearchChange}
							/>

							{/* <PrimaryCTA
								buttonText='Search'
							/> */}
						</div>
					</div>
				</div>

				<button onClick={() => setModalOpen(!ModalOpen)} className={style.DropdownButton}>
					<img src={dropdownIcon} alt="" />
				</button>
			</div>

			<div className={style.FilterCategoryWrapper} style={{ display: ModalOpen ? 'flex' : 'none' }}>
				{
					props.filterArray.map(filterItem =>
						<FilterCategory
							name={filterItem.name}
							itemsArray={[...filterItem.items]}
							onItemSelect={handleItemSelect}
							selectedClass={props.selectedClass}
							selectedElement={props.selectedElement}
							selectedFaction={props.selectedFaction}
							selectedRarity={props.selectedRarity}
							selectedRole={props.selectedRole}
						/>
					)
				}
			</div>
		</div>
	)
}

export default Filter;
