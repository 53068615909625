import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useParams } from "react-router-dom";
import '../AdminPage/Admin.css'
import style from './AdminEditHero.module.css';

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { isAdmin } from '../../utils/userUtils';
import inputCheck from '../../utils/inputCheck';

import AdminSideBar from './../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import MainInput from '../../components/MainInput/MainInput';
import WikiText from '../../components/WikiText/WikiText';
import MainDropdown from '../../components/MainDropdown/MainDropdown';
import AdminHeroPreviewPage from '../AdminHeroPreviewPage/AdminHeroPreviewPage';
import FileUpload from '../../components/FileUpload/FileUpload';

import { Roles } from '../../content/attributes';
import { Classes } from '../../content/attributes';
import { Elements } from '../../content/attributes';
import { Factions } from '../../content/attributes';
import { Rarity } from '../../content/attributes';
import { Editions } from '../../content/attributes';
import { PassiveAbilities } from '../../content/attributes';
import { ActiveAbilities } from '../../content/attributes';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminEditHero = () => {
	const navigate = useNavigate()
	const { user } = useUser()

	const { guardianID } = useParams()
	const [showPreview, setShowPreview] = useState(false);
	// const [hero, setHero] = useState({})
	const [heroData, setHeroData] = useState({
		name: '',
		imageURL: '',
		description: '',
		price: 0,
		role: '',
		class: '',
		faction: '',
		element: '',
		rarity: '',
		edition: '',
		activeAbility: '',
		passiveAbility: '',
		overview: '',
		wikiText: ''
	});

	const getGuardianInfo = () => {
		axios.get(`${API_URL}/guardians/${guardianID}`)
			.then(res => setHeroData(res.data))
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getGuardianInfo()
	}, [])

	const handleSubmit = async (e) => {
		if (user && user.id) {
			console.log(inputCheck(heroData, user, user.id));
			try {
				// Check if the user exists before making the PATCH request
				const userExistsResponse = await axios.get(`${API_URL}/users/${user.id}`);

				// If the user doesn't exist, show an error
				if (userExistsResponse.status !== 200) {
					toast.error('User not found');
					return;
				}

				// Send the PATCH request to update the guardian
				if (inputCheck(heroData, user, user.id)) {
					const response = await axios.patch(`${API_URL}/guardians/${guardianID}`, {
						dataObj: heroData,
						userId: user.id,
					});

					if (response.status === 200) {
						toast.success(`Updated ${heroData.name}`);
						navigate('/admin-heroes');
					} else {
						toast.error('Request failed');
					}
				}
			} catch (err) {
				console.error(err);
				toast.error('An error occurred');
			}
		}
	};



	// Input feild change handlers
	const handleNameChange = event => {
		setHeroData({ ...heroData, name: event.target.value });
	}

	const handleImageLinkChange = event => {
		setHeroData({ ...heroData, imageURL: event.target.value });
	}

	const handleDescriptionChange = event => {
		setHeroData({ ...heroData, description: event.target.value });
	}

	// const handlePriceChange = event => {
	// 	setHeroData({ ...heroData, price: event.target.value });
	// }

	const handleRoleChange = selectedValue => {
		setHeroData({ ...heroData, role: selectedValue });
	}

	const handleClassChange = selectedValue => {
		setHeroData({ ...heroData, class: selectedValue });
	}

	const handleFactionChange = selectedValue => {
		setHeroData({ ...heroData, faction: selectedValue });
	}

	const handleElementChange = selectedValue => {
		setHeroData({ ...heroData, element: selectedValue });
	}

	const handleRarityChange = selectedValue => {
		setHeroData({ ...heroData, rarity: selectedValue });
	}

	const handleEditionChange = selectedValue => {
		setHeroData({ ...heroData, edition: selectedValue });
	}

	const handleActiveAbilityChange = event => {
		setHeroData({ ...heroData, activeAbility: event.target.value });
	}

	const handleActiveAbilityDescriptionChange = event => {
		setHeroData({ ...heroData, activeAbilityDescription: event.target.value });
	}

	const handlePassiveAbilityChange = event => {
		setHeroData({ ...heroData, passiveAbility: event.target.value });
	}

	const handlePassiveAbilityDescriptionChange = event => {
		setHeroData({ ...heroData, passiveAbilityDescription: event.target.value });
	}

	const handleOverviewChange = event => {
		setHeroData({ ...heroData, overview: event.target.value });
	}

	const handleWikiTextChange = event => {
		setHeroData({ ...heroData, wikiText: event.target.value });
	}

	const handleSetUploadLink = (imageLink) => {
		setHeroData({ ...heroData, imageURL: imageLink });
	};


	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<div className='AdminTopBar'>
					<h2>Editing {heroData.name} </h2>
					<div className='AdminButtonWrapper'>
						<span onClick={
							() => setShowPreview(!showPreview)
						}>{showPreview ? 'Hide Preview' : 'Show Preview'}</span>

						<PrimaryCTA buttonText='Update Hero'
							buttonClick={handleSubmit}
						/>
					</div>
				</div>

				{
					showPreview ?
						<AdminHeroPreviewPage
							heroData={heroData}
						/>
						:
						<div className={style.HeroPageContent}>
							<div className={style.HeroPageInputContainer}>
								<div className={style.HeroInputsWrapper}>
									<MainInput
										TitleText='Hero Name'
										placeholder='Hero name'
										change={handleNameChange}
										value={heroData.name || ''}
									/>


									<MainInput
										TitleText='Hero Image Link'
										palceholder='Hero Image Link'
										change={handleImageLinkChange}
										value={heroData.imageURL || ''}
									/>

									<FileUpload
										onUploadComplete={handleSetUploadLink}
									/>

									<MainInput
										TitleText='Hero Description'
										palceholder='Hero Description'
										change={handleDescriptionChange}
										value={heroData.description || ''}
									/>

									<MainInput
										TitleText='Hero Overview'
										palceholder='Hero Overview'
										change={handleOverviewChange}
										value={heroData.overview || ''}
									/>
								</div>

								<div className={style.MainDropdownWrapper}>
									<MainDropdown
										DropdownTitle='Hero Role'
										dataArray={Roles}
										change={handleRoleChange}
										selectedOption={heroData.role || ''}
									/>

									<MainDropdown
										DropdownTitle='Hero Class'
										dataArray={Classes}
										change={handleClassChange}
										selectedOption={heroData.class || ''}
									/>

									<MainDropdown
										DropdownTitle='Hero Faction'
										dataArray={Factions}
										change={handleFactionChange}
										selectedOption={heroData.faction || ''}
									/>

									<MainDropdown
										DropdownTitle='Hero Element'
										dataArray={Elements}
										change={handleElementChange}
										selectedOption={heroData.element || ''}
									/>

									<MainDropdown
										DropdownTitle='Hero Rarity'
										dataArray={Rarity}
										change={handleRarityChange}
										selectedOption={heroData.rarity || ''}
									/>

									<MainDropdown
										DropdownTitle='Hero Edition'
										dataArray={Editions}
										change={handleEditionChange}
										selectedOption={heroData.edition || ''}
									/>
								</div>
							</div>

							<div className={style.AbilitiesWrapper}>
								<div className={style.AbilityInput}>
									<MainInput
										TitleText='Ultimate Ability'
										placeholder='Ultimate Ability'
										change={handleActiveAbilityChange}
										value={heroData.activeAbility}
									/>

									<MainInput
										TitleText='Ultimate Ability Description'
										placeholder='Ultimate Ability Description'
										change={handleActiveAbilityDescriptionChange}
										value={heroData.activeAbilityDescription}
									/>
								</div>

								<div className={style.AbilityInput}>
									<MainInput
										TitleText='Passive Ability'
										placeholder='Passive Ability'
										change={handlePassiveAbilityChange}
										value={heroData.passiveAbility}
									/>

									<MainInput
										TitleText='Passive Ability Description'
										placeholder='Passive Ability Description'
										change={handlePassiveAbilityDescriptionChange}
										value={heroData.passiveAbilityDescription}
									/>
								</div>
							</div>


							<div>
								<WikiText
									TitleText='Hero Wiki'
									change={handleWikiTextChange}
									value={heroData.wikiText || ''}
								/>
							</div>
						</div>
				}
			</div>
		</div>
	)
}

export default AdminEditHero;