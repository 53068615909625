import React, { useState, useEffect } from 'react';
import axios from 'axios';

import style from './AdminUsersPage.module.css';
import UserCard from '../../components/UserCard/UserCard';
import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';
import Spinner from '../../components/Spinner/Spinner';

const API_URL = process.env.REACT_APP_BACKEND_API;

const AdminUsersPage = () => {
	const [users, setUsers] = useState(null);

	const getUsers = () => {
		axios.get(`${API_URL}/users`).then((res) => setUsers(res.data));
	};

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<div className={style.AdminUserPage}>
			<AdminSideBar />

			<div className="AdminContentWrapper">
				<div className="AdminTopBar">
					<span>Users</span>
				</div>

				<div className={style.UserCardsWrapper}>
					{users ? (
						users.map((user) => (
							<UserCard
								key={user.userID}
								userId={user.userID}
								username={user.username}
								userRole={user.userRole}
								userImage={user.userProfileURL}
								guardianID={user.guardianID}
							/>
						))
					) : (
						<div className='SpinnerContainer'>
							<Spinner />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AdminUsersPage;
