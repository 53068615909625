import React, { useState, useEffect } from 'react';
import style from './Pets.module.css';

import { Link } from 'react-router-dom';
import axios from 'axios';

import PetCard from '../../components/PetCard/PetCard';
import Search from '../../components/Search/Search';
import Filter from '../../containers/Filter/Filter';

import { Classes, Rarity, petFactions } from '../../content/attributes';
import Spinner from '../../components/Spinner/Spinner';


const Pets = () => {
	const [pets, setPets] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [selectedClass, setSelectedClass] = useState([]);
	const [selectedRarity, setSelectedRarity] = useState([]);
	const [selectedFaction, setSelectedFaction] = useState([]);

	const API_URL = process.env.REACT_APP_BACKEND_API;

	const getPets = () => {
		axios.get(`${API_URL}/pets`)
			.then(res => setPets(res.data.pets))
			.catch(err => console(err))
	}

	useEffect(() => {
		getPets()
	}, [])

	const filteredPets = pets
		? pets.filter((pet) => {
			const classMatch =
				selectedClass.length === 0 || selectedClass.includes(pet.class);

			const rarityMatch =
				selectedRarity.length === 0 || selectedRarity.includes(pet.rarity);

			const factionMatch =
				selectedFaction.length === 0 || selectedFaction.includes(pet.Faction);

			const searchMatch =
				searchText === '' ||
				pet.name.toLowerCase().includes(searchText.toLowerCase());

			return (
				factionMatch &&
				classMatch &&
				rarityMatch &&
				searchMatch
			);
		})
		: null;


	return (
		<div className={style.PetsPage}>
			<div className={style.PetsContentWrapper}>
				<h2 className='PageHeading'>Pets</h2>

				<Filter
					filterArray={[
						{
							name: 'Class',
							items: Classes
						},

						{
							name: 'Rarity',
							items: Rarity
						},

						{
							name: 'Faction',
							items: petFactions
						}
					]}

					setSearchText={setSearchText}
					selectedClass={selectedClass}
					setSelectedClass={setSelectedClass}
					selectedRarity={selectedRarity}
					setSelectedRarity={setSelectedRarity}
					selectedFaction={selectedFaction}
					setSelectedFaction={setSelectedFaction}
				/>

				<div className={style.PetsPageContent}>
					{filteredPets !== null ? (
						filteredPets.length > 0 ? (
							filteredPets
								.sort((a, b) => a.name.localeCompare(b.name)) // Sort the guardians alphabetically based on the name
								.map((pet) => (
									<Link to={`/pet/${pet._id}`} key={pet._id}>
										<PetCard
											petName={pet.name}
											petDescription={pet.description}
											petPrice={pet.price}
											petRole={pet.role}
											petClass={pet.class}
											petRarity={pet.rarity}
											petImageURL={pet.imageURL}
										/>
									</Link>
								))
						) : (
							<span>No results found.</span>
						)
					) : (
						<div className='SpinnerContainer'>
							<Spinner />
						</div>
					)}
				</div>


			</div>
		</div>
	)
}

export default Pets;