import './App.css';
import { useUser } from '@clerk/clerk-react';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { UserContext } from './contexts/UserContext';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from './components/NavBar/Navbar';
import MobileNavBar from './components/MobileNavBar/MobileNavBar';
import Home from './pages/Home/Home';
import Guardians from './pages/Guardians/Guardians';
import Dungeons from '../src/pages/Dungeons/Dungeons';
import DungeonLevels from './pages/DungeonLevels/DungeonLevels';
import Guardian from './pages/GuardianWiki/GuardianWiki';
import Login from './pages/UserAuth/Login/Login';
import Register from './pages/UserAuth/Register/Register';
import TeamComposition from './pages/TeamComposition/TeamComposition';
import DungeonWiki from './pages/DungeonWiki/DungeonWiki';
import Pets from './pages/Pets/Pets';
import PetWiki from './pages/PetWiki/PetWiki';

import AdminHeroes from './pages/AdminHeroes/AdminHeroes';
import AdminAddHero from './pages/AdminAddHero/AdminAddHero';
import AdminHeroPreviewPage from './pages/AdminHeroPreviewPage/AdminHeroPreviewPage';
import AdminDungeons from './pages/AdminDungeons/AdminDungeons';
import AdminAddDungeonLevel from './pages/AdminAddDungeonLevel/AdminAddDungeonLevel';
import AdminPets from './pages/AdminPets/AdminPets';
import AdminAddPet from './pages/AdminAddPet/AdminAddPet';
import AdminEditPet from './pages/AdminEditPet/AdminEditPet';
import AdminEditDungeon from './pages/AdminEditDungeon/AdminEditDungeon';
import AdminEditDungeonLevel from './pages/AdminEditDungeonLevel/AdminEditDungeonLevel';
import AdminDungeonLevels from '../src/pages/AdminDungeonLevels/AdminDungeonLevels';


import {
	BrowserRouter as Router,
	Routes,
	Route,
	// Link,
	// useLocation
} from 'react-router-dom';
import AdminAddDungeon from './pages/AdminAddDungeons/AdminAddDungeon';
import AdminEditHero from './pages/AdminEditHero/AdminEditHero';
import AdminUsersPage from './pages/AdminUsersPage/AdminUsersPage';

function App() {
	const { user } = useUser()
	const [dbUser, setDbUser] = useState({})
	const API_URL = process.env.REACT_APP_BACKEND_API;

	const addUserToDatabase = useCallback(() => {
		axios.post(`${API_URL}/users`, {
			email: user.primaryEmailAddress.emailAddress,
			username: user.username,
			userID: user.id,
			userProfileURL: user.profileImpageUrl,
		}).then(
			res => console.log(res)
		).catch(err => err)
	}, [user])

	const getDbUser = () => {
		axios.get(`${API_URL}/users/${user.id}`).then((res) => setDbUser(res.data[0]));
	}

	useEffect(() => {
		if (user) {
			addUserToDatabase()
			getDbUser()
		}
	}, [addUserToDatabase, user])

	return (
		<Router>
			<div className="App">
				<UserContext.Provider
					value={
						dbUser
					}
				>
					<div className='AdminPages'>
						<Routes>
							<Route
								exact
								path='/admin'
								element={<AdminHeroes />}
							/>

							<Route
								exact
								path='/admin-heroes'
								element={<AdminHeroes />}
							/>

							<Route
								exact
								path='/admin-dungeons'
								element={<AdminDungeons />}
							/>

							<Route
								exact
								path='/admin-dungeon-levels/:dungeonID'
								element={<AdminDungeonLevels />}
							/>

							<Route
								exact
								path='/add-hero'
								element={<AdminAddHero />}
							/>

							<Route
								exact
								path='/add-dungeon'
								element={<AdminAddDungeon />}
							/>

							<Route
								exact
								path='/hero-preview'
								element={<AdminHeroPreviewPage />}
							/>

							<Route
								exact
								path='/add-dungeon-level/:dungeonID'
								element={<AdminAddDungeonLevel />}
							/>

							<Route
								exact
								path='/admin-pets'
								element={<AdminPets />}
							/>

							<Route
								exact
								path='/add-pet'
								element={<AdminAddPet />}
							/>

							<Route
								exact
								path='/edit-pet/:petID'
								element={<AdminEditPet />}
							/>

							<Route
								exact
								path='/edit-hero/:guardianID'
								element={<AdminEditHero />}
							/>

							<Route
								exact
								path='/edit-dungeon/:dungeonID'
								element={<AdminEditDungeon />}
							/>

							<Route
								exact
								path='/edit-dungeon-level/:dungeonID/:dungeonLevelID'
								element={<AdminEditDungeonLevel />}
							/>

							<Route
								exact
								path='/admin-users'
								element={<AdminUsersPage />}
							/>

						</Routes>
					</div>

					<>
						{
							window.innerWidth <= 950 ? <MobileNavBar /> : <Navbar />
						}

						<div className='Pages'>
							<Routes>
								<Route
									exact
									path='/'
									element={<Home />}
								/>

								<Route
									exact
									path='/home'
									element={<Home />}
								/>

								<Route
									exact
									path='/guardians'
									element={<Guardians />}
								/>

								<Route
									exact
									path='/login'
									element={<Login />}
								/>

								<Route
									exact
									path='/register'
									element={<Register />}
								/>

								<Route
									exact
									path='/dungeons'
									element={<Dungeons />}
								/>

								<Route
									exact
									path='/guardian/:guardianID'
									element={<Guardian />}
								/>

								<Route
									exact
									path='/team-composition'
									element={<TeamComposition />}
								/>

								<Route
									exact
									path='/dungeon-levels/:dungeonID'
									element={<DungeonLevels />}
								/>

								<Route
									exact
									path='/dungeon/:dungeonID/:dungeonLevelID'
									element={<DungeonWiki />}
								/>

								<Route
									exact
									path='/pets'
									element={<Pets />}
								/>

								<Route
									exact
									path='/pet/:petID'
									element={<PetWiki />}
								/>
							</Routes>
							<ToastContainer
								position="top-right"
								autoClose={5000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								theme="dark"
							/>
						</div>
					</>

				</UserContext.Provider>
			</div>
		</Router>
	);
}

export default App;