import commonIcon from '../assets/icons/common-icon.png';
import darkIcon from '../assets/icons/dark-icon.png';
import earthIcon from '../assets/icons/earth-icon.png';
import empireIcon from '../assets/icons/empire-icon.png';
import epicIcon from '../assets/icons/epic-icon.png';
import fireIcon from '../assets/icons/fire-icon.png';
import gladeIcon from '../assets/icons/glade-icon.png';
import hordeIcon from '../assets/icons/horde-icon.png';
import huntersIcon from '../assets/icons/hunters-icon.png'
import legendaryIcon from '../assets/icons/legendary-icon.png'
import lightIcon from '../assets/icons/light-icon.png'
import mageIcon from '../assets/icons/mage-icon.png'
import meleeIcon from '../assets/icons/melee-icon.png'
import rangedIcon from '../assets/icons/ranger-icon.png'
import rareIcon from '../assets/icons/rare-icon.png'
import scoutsIcon from '../assets/icons/scouts-icon.png'
import waterIcon from '../assets/icons/water-icon.png'
import workersIcon from '../assets/icons/workers-icon.png'
import damageIcon from '../assets/icons/damage-icon.png'
import controlIcon from '../assets/icons/control-icon.png'
import bruiserIcon from '../assets/icons/bruiser-icon.png'
import tankIcon from '../assets/icons/tank-icon.png'
import healerIcon from '../assets/icons/healer-icon.png'
import supportIcon from '../assets/icons/support-icon.png'
import scoutIcon from '../assets/icons/scouts-icon.png'
import NAIcon from '../assets/icons/naIcon.svg';


export const Roles = [{
		name: 'N/A',
		icon: NAIcon
	},
	{
		name: 'Damage',
		icon: damageIcon
	},
	{
		name: 'Control',
		icon: controlIcon
	},
	{
		name: 'Bruiser',
		icon: controlIcon
	},
	{
		name: 'Tank',
		icon: tankIcon
	},
	{
		name: 'Healer',
		icon: healerIcon
	},
	{
		name: 'Support',
		icon: supportIcon

	},
]

export const petFactions = [{
		name: 'N/A',
		icon: NAIcon
	},
	{
		name: 'Worker',
		icon: workersIcon
	},

	{
		name: 'Hunter',
		icon: huntersIcon
	},

	{
		name: 'Scout',
		icon: scoutIcon
	},
]

export const Classes = [{
		name: 'N/A',
		icon: NAIcon
	},
	{
		name: 'Mage',
		icon: mageIcon
	},

	{
		name: 'Melee',
		icon: meleeIcon
	},

	{
		name: 'Ranged',
		icon: rangedIcon
	},
]

export const Elements = [{
		name: 'N/A',
		icon: NAIcon
	},
	{
		name: 'Dark',
		icon: darkIcon
	},

	{
		name: 'Earth',
		icon: earthIcon
	},

	{
		name: 'Water',
		icon: waterIcon
	},

	{
		name: 'Fire',
		icon: fireIcon
	},

	{
		name: 'Light',
		icon: lightIcon
	},
]

export const Factions = [{
		name: 'N/A',
		icon: NAIcon
	},
	{
		name: 'Empire',
		icon: empireIcon
	},
	{
		name: 'Glade',
		icon: gladeIcon
	},
	{
		name: 'Horde',
		icon: hordeIcon
	},
]

export const Rarity = [{
		name: 'N/A',
		icon: NAIcon
	},
	{
		name: 'Epic',
		icon: epicIcon
	},
	{
		name: 'Legendary',
		icon: legendaryIcon
	},
	{
		name: 'Rare',
		icon: rareIcon
	},
	{
		name: 'Common',
		icon: commonIcon
	},
]

export const Editions = [{
		name: 'N/A',
		icon: NAIcon
	},
	{
		name: 'Normal',
	},
	{
		name: 'Elite'
	},
	{
		name: 'Warrior'
	},
	{
		name: 'Mythic'
	},
]

export const PassiveAbilities = [{
		name: 'Foreseen Aura'
	},
	{
		name: 'Share Essence'
	},
	{
		name: 'Interpretive Dance'
	},
]

export const ActiveAbilities = [{
		name: 'Swang Song'
	},
	{
		name: 'Blue Flames'
	},
	{
		name: 'Pick a Card'
	},
]