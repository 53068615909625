import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { useParams, Link, useNavigate } from "react-router-dom";
import style from '../AdminAddDungeons/AdminAddDungeon.module.css'
import PrimaryCTA from '../../components/Buttons/PrimaryCTA/PrimaryCTA';

import AdminSideBar from '../../containers/AdminSideBar/AdminSideBar';
import MainInput from '../../components/MainInput/MainInput';
import MainDropdown from '../../components/MainDropdown/MainDropdown';
import DungeonLevelCard from '../../components/DungeonLevelCard/DungeonLevelCard';
import FileUpload from '../../components/FileUpload/FileUpload';


import { Elements } from '../../content/attributes'
import { Tiers } from '../../content/dungeonAttribute';
import { toast } from 'react-toastify';
import handleRequest from '../../utils/inputCheck';


const AdminEditDungeon = () => {
	const { dungeonID } = useParams()
	const API_URL = process.env.REACT_APP_BACKEND_API;
	const navigate = useNavigate()
	const { user } = useUser()

	const [dungeonData, setDungeonData] = useState(
		{
			name: '',
			imageURL: '',
			description: '',
			tier: '',
			element: '',
			DungeonLevels: []
		}
	)

	const getDungeonInfo = () => {
		axios.get(`${API_URL}/dungeons/${dungeonID}`)
			.then(res => setDungeonData(res.data))
			// .then(res => setHeroData(hero))
			.catch(err => console.log(err))
	}

	const handleSubmit = async (e) => {
		toast('updating dungeon')
		handleRequest(
			dungeonData,
			user,
			user.id,
			`${API_URL}/dungeons/${dungeonID}`,
			'patch',
			`Updated ${dungeonData.name}`,
			null,
			null
		);
	};

	// '/:id/levels/:levelId'
	const deleteDungeonLevel = async (id, userId, levelId) => {
		toast('deleting level');
		handleRequest(
			{},
			user,
			userId,
			`${API_URL}/dungeons/${dungeonID}/levels/${levelId}`,
			'delete',
			'dungeon level deleted',
			null,
			null
		);

		// Remove the deleted dungeon level from the state
		setDungeonData((prevData) => ({
			...prevData,
			DungeonLevels: prevData.DungeonLevels.filter(
				(level) => level._id !== levelId
			),
		}));
	};


	useEffect(() => {
		getDungeonInfo()
	}, [])

	const handleNameChange = event => {
		setDungeonData({ ...dungeonData, name: event.target.value });
	}

	const handleImageLinkChange = event => {
		setDungeonData({ ...dungeonData, imageURL: event.target.value });
	}

	const handleDescriptionChange = event => {
		setDungeonData({ ...dungeonData, description: event.target.value });
	}

	const handleTierChange = selectedValue => {
		setDungeonData({ ...dungeonData, tier: selectedValue });
	}

	const handleElementChange = selectedValue => {
		setDungeonData({ ...dungeonData, element: selectedValue });
	}

	const handleSetUploadLink = (imageLink) => {
		setDungeonData({ ...dungeonData, imageURL: imageLink });
	};

	return (
		<div className='AdminPage'>
			<AdminSideBar />

			<div className='AdminContentWrapper'>
				<div className='AdminTopBar'>
					<span>Edit dungeon</span>
					<div>
						<PrimaryCTA
							buttonText='Save Dungeon'
							buttonClick={handleSubmit}
						/>
					</div>
				</div>

				<div className={style.AddDungeonContent}>
					<div className={style.AddDungeonInputWrapper}>
						<div className={style.MainInputWrapper}>
							<MainInput
								TitleText='Dungeon name'
								placeholder='Dungeon name'
								change={handleNameChange}
								value={dungeonData.name || ''}
							/>

							<MainInput
								TitleText='Dungeon image link'
								placeholder='Dungeon name'
								change={handleImageLinkChange}
								value={dungeonData.imageURL || ''}
							/>

							<FileUpload
								onUploadComplete={handleSetUploadLink}
							/>

							<MainInput
								TitleText='Dungeon Description'
								placeholder='Dungeon description'
								change={handleDescriptionChange}
								value={dungeonData.description || ''}
							/>
						</div>

						<div className={style.MainDropdownWrapper}>
							<MainDropdown
								DropdownTitle='Dungeon Element'
								dataArray={Elements}
								change={handleElementChange}
								selectedOption={dungeonData.element || ''}
							/>

							<MainDropdown
								DropdownTitle='Dungeon Tier'
								dataArray={Tiers}
								change={handleTierChange}
								selectedOption={dungeonData.tier || ''}
							/>
						</div>
					</div>

					<div>
						<div className='AdminTopBar'>
							<span>Dungeon Levels</span>
							<div>
								<Link to={`/add-dungeon-level/${dungeonID}`}>
									<PrimaryCTA 
									buttonText='+ Add level'
									buttonClick = {() => null}
									 />
								</Link>
							</div>
						</div>

						<div className={style.DungeonsWrapper}>
							{dungeonData.DungeonLevels.length > 0 ? (
								dungeonData.DungeonLevels.map((level) => (

									<DungeonLevelCard
										level={level.level}
										imageURL={level.imageURL}
										power={level.squadPower}
										dungeonID={dungeonID}
										dungeonLevelID={level._id}
										editable={true}
										delete={() => deleteDungeonLevel(dungeonData._id, user.id, level._id)}
									/>
								))
							) : (
								<p>Loading...</p>
							)}
						</div>

					</div>

				</div>
			</div>
		</div>
	)
}

export default AdminEditDungeon;