import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';
import reportWebVitals from './reportWebVitals';
import { isAdmin } from '../src/utils/userUtils';
import { Analytics } from '@vercel/analytics/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const frontendAPI = process.env.REACT_APP_CLERK_FRONTEND_API;
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

root.render(
	<React.StrictMode>
		<Analytics />
		<ClerkProvider
			publishableKey={clerkPubKey}
			appearance={{
				baseTheme: dark,
				variables: {
					colorBackground: '#1D2637'
				}
			}}
		>
			<App />
		</ClerkProvider>
	</React.StrictMode>
);

reportWebVitals();


// // Usage example:
// // Usage example:
// const userId = 'user_2JBPp2B8RW86iJdp5MkWdbEjYDv'; // Replace with the actual user ID
// console.log((async () => await isAdmin(userId))());