import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from './DungeonLevels.module.css'
import DungeonLevelCard from '../../components/DungeonLevelCard/DungeonLevelCard';
import { Link, useParams } from 'react-router-dom';

import dungeonIcon from '../../assets/dungeonIcon.svg';
import elementIcon from '../../assets/element_icon.svg';
import tierIcon from '../../assets/tierIcon.svg';
import Spinner from '../../components/Spinner/Spinner';

const DungeonLevels = () => {
	const { dungeonID } = useParams()
	const API_URL = process.env.REACT_APP_BACKEND_API;
	const [dungeonData, setDungeonData] = useState(
		{
			name: '',
			imageURL: '',
			description: '',
			tier: '',
			element: '',
			DungeonLevels: []
		}
	)

	const getDungeonInfo = () => {
		axios.get(`${API_URL}/dungeons/${dungeonID}`)
			.then(res => setDungeonData(res.data))
			// .then(res => setHeroData(hero))
			.catch(err => console.log(err))
	}

	useEffect(() => {
		getDungeonInfo()
	}, [])

	return (
		<div>
			<h2 className='PageHeading'>Dungeon - {dungeonData.name}</h2>

			<div className={style.DungeonInfoContainer}>
				<div className={style.DungeonInfoWrapper}>
					<img src={dungeonIcon} alt="" />
					<div className={style.DungeonInfo}>
						<span className={style.DungeonInfoHeading}>Dungeons</span>
						<span className={style.DungeonAttribute}>{dungeonData.DungeonLevels.length}</span>
					</div>
				</div>

				<div className={style.DungeonInfoWrapper}>
					<img src={elementIcon} alt="" />
					<div className={style.DungeonInfo}>
						<span className={style.DungeonInfoHeading}>Element</span>
						<span className={style.DungeonAttribute}>{dungeonData.element}</span>
					</div>
				</div>

				<div className={style.DungeonInfoWrapper}>
					<img src={tierIcon} alt="" />
					<div className={style.DungeonInfo}>
						<span className={style.DungeonInfoHeading}>Tier</span>
						<span className={style.DungeonAttribute}>{dungeonData.tier}</span>
					</div>
				</div>
			</div>

			<div className={style.DungeonsWrapper}>
				{dungeonData.DungeonLevels.length > 0 ? (
					dungeonData.DungeonLevels.map((level) => (
						<Link to={`/dungeon/${dungeonID}/${level._id}`} key={level.id}>
							<DungeonLevelCard
								level={level.level}
								imageURL={level.imageURL}
								power={level.squadPower}
								dungeonID={dungeonID}
								dungeonLevelID={level._id}
								editable={false}
							/>
						</Link>
					))
				) : (
					<div className='SpinnerContainer'>
						<Spinner />
					</div>
				)}
			</div>
		</div>
	);
}

export default DungeonLevels;