import React, { useState } from 'react';
import axios from 'axios';
// import style from './PetCard.module.css';
import style from '../GuardianCard/GuardianCard.module.css'

import ToolTip from '../ToolTip/ToolTip';

import { Rarity, Classes } from '../../content/attributes';
import NAIcon from '../../assets/icons/naIcon.svg'
import elipsis from '../../assets/elipsis.svg';

const API_URL = 'https://gog-backend-test.vercel.app';


const PetCard = props => {
	const petCardWrapperStyle = {
		backgroundImage: 'url(' + props.petImageURL + ')'
	}

	const [openToolTip, setOpenToolTip] = useState(false);

	const selectedRarity = Rarity.find(role => role.name === props.petRarity)
	const rarityIcon = selectedRarity ? selectedRarity.icon : NAIcon

	const selectedClass = Classes.find(heroClass => heroClass.name === props.petClass)
	const classIcon = selectedClass ? selectedClass.icon : NAIcon

	return (
		<div className={style.GuardianCardWrapper}
			style={petCardWrapperStyle}
		>
			<div
				alt=""
				className={style.GuardianImage}
			/>
			<div className={style.GuardianCardInfo}>
				<div className={style.Guardian}>
					<div className={style.GuardianCardInfo}>
						<div className={style.GuardianNameWrapper}>
							<span className={style.GuardianName}>{props.petName}</span>
							<span className={style.GuardianTagline}>
								{props.petDescription.length > 15
									? `${props.petDescription.substring(0, 15)}...`
									: props.petDescription}
							</span>

						</div>

						{
							props.editable ?
								<div className={style.Elipsis}
								>
									<div
										className={style.ElipsisWrapper}
										onClick={() => setOpenToolTip(!openToolTip)}
									>
										<img
											src={elipsis}
											alt=""
											className={style.ElipsisIcon} />
									</div>

									{
										openToolTip ?
											<ToolTip
												viewLink={`/pet/${props.petID}`}
												editLink={`/edit-pet/${props.petID}`}
												delete={props.delete}
											/>
											: null

									}
								</div>
								: null
						}
					</div>

					<div className={style.GuardianAttributeWrapper}>
						<div className={style.GuardianAttribute}>
							<img
								src={classIcon}
								className={style.AttributeIcon}
								alt="" />

							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>Class</span>
								<span className={style.GuardianAttributeText}>{props.petClass}</span>
							</div>
						</div>


						<div className={style.GuardianAttribute}>
							<img
								src={rarityIcon}
								className={style.AttributeIcon}
								alt="" />

							<div className={style.GuardianAttributeTextWrapper}>
								<span className={style.GuardianAttributeHeading}>Rarity</span>
								<span className={style.GuardianAttributeText}>{props.petRarity}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PetCard;